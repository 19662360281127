

/*______ Colors ______*/

$csfred: #d8292d;
$csfredpale: red;
$csfwhite: #fff;
$csfcreme: #ebeadc;
$csfgrey: #b2b2b2;
$csfbackground: #f6f5f4;
$csfblack: #282828;


/*______ Fonts ______*/

$sans-serif: 'AvenirNextLTW01-Regular', Arial, Helvetica, sans-serif;
$demi:'Avenir Next LT W01 Demi';
$bold:'Avenir Next LT W01 Bold';
$heavy:'AvenirNextLTW01-Heavy';

$demicn:'AvenirNextLTW01-DemiCn';
$heavycn:'AvenirNextLTW01-HeavyCn_721323';

$serif: Georgia, "Times New Roman", serif;