/*______ Basics ______*/

html { overflow-y: auto; text-size-adjust: auto !important; -moz-text-size-adjust: auto !important; -webkit-text-size-adjust: auto !important; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

::-moz-selection {
  background: rgb(249,224,213);
  color: #000;
  text-shadow: none;
}
::selection {
  background: rgb(249,224,213);
  color: #000;
  text-shadow: none;
}

a {
   outline: 0 !important;
}

small, .small { }
strong, b, th { }
h1, h2, h3, h4, h5, h6  { text-rendering: optimizeLegibility; font-weight: normal; letter-spacing: 1px; }

ul, ol { margin: 0; padding: 0 0 0 1.6em; }
li { margin: 0; padding: 0; }
ol { list-style-type: decimal; }
section.content article ul li { list-style: none; }
section.content article ul li:before { content: "\2013 \0020" !important; }
nav ul, nav li { margin: 0; padding: 0; list-style:none; list-style-image: none; }

table { border-collapse: collapse; border-spacing: 0; }
td, th { vertical-align: top; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }
input, select { vertical-align: middle; }
textarea { overflow: auto; white-space: normal; }

sub, sup { font-size: 75%; line-height: 0; position: relative; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }
.footnotes li a:rev { display: none; }

.none { display: none; } 

.clearfix:after {
   content: " "; /* Older browser do not support empty content */
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.js	.nav-collapse-0.opened { max-height: auto !important; }

#map { width: 100%; height: 500px; }

/*------------------------------------------------------------
	Browser Reset
--------------------------------------------------------------*/

body { line-height: 1; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%;	-moz-osx-font-smoothing: grayscale; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav,object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video  { 
	font-family: inherit; 
	font-size: 100%; 
	font-weight: inherit; 
	font-style: inherit; 
	vertical-align: baseline; 
	white-space: normal; 
	margin: 0; 
	padding: 0; 
	border: 0; 
	outline: 0;
	background: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { 
	display: block; }
								  
ol, ul { list-style: none; }

blockquote, q { quotes: none; }

:focus { outline: 0; }

table { 
	border-collapse: collapse; 
	border-spacing: 0; }

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle;
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
  /* vertical-align: bottom; */ /* Suppress the space beneath the baseline */
  /* Responsive images */
  /* @todo Add responsive embedded video. */
  max-width: 100%;
  height: auto;
  width: auto;
  }
  
  
  
.wf-loading {
	h1, p { visibility: hidden !important; }
}
  
  
/*______ MS-Viewport Bug  ______*/

@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }
