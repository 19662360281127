/*	-------------------------------------------------
	Cuisine sans frontières
	HTML / CSS 
	Studio Roth&Maerchy 2017
	http://www.rothmaerchy.com
	-------------------------------------------------  */


@import 'variables';
@import 'mixins';
@import 'reset';
@import 'slick-theme';

/*______ Typography ______*/


h1 a { color: $csfblack; text-decoration: none; }
h1 a:hover { color: #000; border: 0; }

article strong { font-family: $demi; font-weight: normal; letter-spacing: 0.05em;}
small, p small, .small p { font-size: 0.9em; color: #999; }

ul { list-style-position: inside; }
ol { list-style-type: decimal; list-style-position: inside; }



/*______ Links and Navigation ______*/

a { color: $csfred; text-decoration: none !important;
	&:hover { color: #000; text-decoration: none; }
	&:active { color: $csfred !important; }
}


a:visited { border: 0; text-decoration: none !important; }

a.anchor { display: block; height: 227px; margin-top: -227px; visibility: hidden; }

a.btn { text-transform: uppercase; letter-spacing: 1px; text-decoration: none; }

a.btn { background-color: $csfred; background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; font-family: $heavy; text-transform: uppercase !important; font-size: 1.2em;
&:hover { color: $csfcreme; }
}

a.img, a.img:hover { border: 0 !important; }

button { text-transform: uppercase; }

@media print {
  a[href]:after {
    content: none !important;
  }
}

/*______ Basics, Body & Menu ______*/


body { width: 100%; height: 100%; min-height: 100%; margin: 0 0 0 0; padding: 0; font: 16px/160%; background: $csfbackground; background-attachment: fixed; font-family: $sans-serif; font-weight: normal; font-style: normal;

@media only screen and (min-width: 768px) { font-size: 14px; }

div#frame {
@media only screen and (min-width: 768px) { min-height:100%; position: relative; }
}

.datepicker { font-weight: bold;
&.dropdown-menu { background-color: $csfred; }
td, th { color: $csfwhite; }
table {
	tr th:hover { background: $csfredpale !important; cursor: pointer; }
	tr td.day:hover, tr td.day.focused { background: $csfredpale; cursor: pointer; }
	tr td.old, tr td.new { color: $csfredpale; }
	tr td.disabled, tr td.disabled:hover { background: none; color: $csfredpale; cursor: default; }
}
}
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $csfred;
  border-top: 0;
  position: absolute;
}


.dropdown-menu { z-index: 10000; }

.datepicker table tr td.active.active { background: $csfcreme; color: $csfblack;
&:hover { background: rgb(168,0,49); }
}

.pswp { z-index: 10000;}

#buorg { z-index: 1111111 !important; position: absolute !important; }
.buorg { z-index: 1111111 !important; position: absolute !important; width: 100%; padding: 1em 4em; top: 0px; left: 0px; border-bottom: 1px solid #A29330; background: #FDF2AB no-repeat 13px center url(//browser-update.org/img/small/c.gif); text-align: left; cursor: pointer; font-family: Arial,Helvetica,sans-serif !important; color: #000; font-size: 12px;
	#buorgclose { float: right; }
	a { border-bottom: 1px solid #666;}
}

.fancybox-overlay { z-index: 18000; }
.fancybox-wrap { z-index: 18010; }
	
&.projects {
	main#content {	
		#project-grid {
			.project {
				a { 
					img { }
				}
			}
		}
	}
}
}

.carousel-control.left, .carousel-control.right { background: none; }

.carousel li .active { margin: 0 0 -1px 0 !important; }

.carousel .carousel-indicators { display: none;
@media only screen and (min-width: 768px) { display: inline-table; }
li {
@media only screen and (min-width: 768px) { display: inline-block;  }
}
}

.carousel-inner {
    > .item { 
	    -webkit-transition: 1.5s ease-in-out left;
        -moz-transition: 1.5s ease-in-out left;
		-o-transition: 1.5s ease-in-out left;
         transition: 1.5s ease-in-out left;
	    @media all and (transform-3d), (-webkit-transform-3d), (-moz-transform-3d) {
        -webkit-transition: -webkit-transform 1.5s ease-in-out 0s;
        -moz-transition: -moz-transform 1.5s ease-in-out 0s;
		-o-transition: -o-transform 1.5s ease-in-out 0s;
		transition: transform 1.5s ease-in-out 0s;
		}
		
    }
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

html, 
body, 
.carousel, 
.carousel-inner, 
.carousel-inner .item {
    height: 100%;
}

.item:nth-child(1) {
}

.item:nth-child(2) {
}

.item:nth-child(3) {
}


.tos-desktop .tos-wrapper.tos-fixed { background: #000 !important; }
.tos-close { top: 60px; left: auto; }
.tos-close span:before, .tos-close span:after {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: 0;
  margin-right: 0;
}
.tos-wrapper .tos-caption { font-size: 1em; 
@media only screen and (min-width: 768px) { font-size: 1.2em; line-height: 25px; }
@media only screen and (min-width: 992px) { font-size: 1.2em; line-height: 25px; }
}

.dds-widget-container { margin: 0 15px; }
#lema-container.lema-container { margin: 0 auto !important;
   .lema-step-header-text { padding-left: 52px !important; }
   .lema-step-number { left: 12px !important; }
}

.video-container { position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden; margin: 2em 0;
    iframe { position: absolute; top:0; left: 0; width: 100% !important; height: 100% !important; }
}

.jscroll-inner { overflow: hidden; }

#infscr-loading { text-align: center; margin: 20px 0;
	img { margin: 20px 0; }
	div { margin: 0 auto; font-size: 2em; }
}

#cuisine-sf-default-stored_customer_donation_receipt { display: none !important; }
#cuisine-sf-default-stored_customer_donation_receipt + label { display: none !important; }

/*______ Content ______*/


header#site { position: fixed; top: -10px; left: 0; margin: 0; width: 100%; z-index: 8000; background-color: $csfblack; padding: 0.5em 0 0 0; 
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	
@media only screen and (min-width: 480px) { left: 0; top: -5px; margin: 0 0 0 0; }
@media only screen and (min-width: 768px) { flex-direction: row; }
@media only screen and (min-width: 992px) {  overflow: visible; position: fixed; top: -5px; left: 0; padding: 0 0 0 0;
	-webkit-box-shadow: 0 0 100px rgba(0,0,0,0.3);
	-moz-box-shadow: 0 0 100px rgba(0,0,0,0.3);
	box-shadow: 0 0 100px rgba(0,0,0,0.3);
	}   
	
@media only screen and (min-width: 1025px) { margin: auto; }
@media only screen and (min-width: 1200px) {  }
&.noimage {
@media only screen and (min-width: 992px) { }
}
&.smaller {
	#logo { 
	    img.desktopno { display: none; }
	    img.desktopyes { margin-top: -200px; }
    }
    
@media only screen and (min-width: 992px) { 

    #logo { height: 146px; top: 5px;
	    img.desktopno { display: none; }
	    img.desktopyes { margin-top: 0; display: block; }
    }
    #main {
        a { }
	}
	#meta { }
}

}

	#logo { float: none; text-align: left; border: 0 dotted DodgerBlue; width: 120px;
	@media only screen and (min-width: 480px) { }
	@media only screen and (min-width: 992px) { 
			width: 290px;
			height: auto;
			margin: 0;
			text-align: left;
			transition: all 0.3s;
		}
		h1 { font-size: 2em; font-weight: normal; font-style: normal; text-transform: uppercase;
		@media only screen and (min-width: 480px) { font-size: 0; }
			a { color: #000; margin: 0; padding: 0; border: 0; 
				img {width: 100%; height: auto; 
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					-ms-transition: all 0.3s;
					-o-transition: all 0.3s;
					transition: all 0.3s;
				@media only screen and (min-width: 768px) { width: 100%; height: auto; }
				}
			}
		}
		.mobilelogo { position: absolute; top: 10px; left: 42px; z-index: -10; width: 43px; height: auto;
		@media only screen and (min-width: 992px) { display: none; }
			img { width: 43px !important; height: auto; }
		}
	}
	a.navtrigger { position: absolute; top: 28px; right: 20px; float: right; width: auto; padding: 0; font-size: 1em; background: none; color: #ccc !important; z-index: 100000; display: block;
	@media only screen and (min-width: 992px) { display: none !important; }
	&:hover { color: $csfwhite; }
		img { width: 32px; height: 32px; }
	}
		
	a.mobiledonate { position: absolute; top: 32px; right: 75px; padding: 2px 10px; background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; font-family: $heavy; color: $csfwhite; text-transform: uppercase; font-size: 1.2em;
	&:hover { color: $csfcreme; }
	&:active { color: $csfblack !important; }
	@media only screen and (min-width: 992px) { display: none; }
	}
	
	ul#meta { font-weight: normal; font-style: normal; font-family: $demi; border: 0 dotted DodgerBlue; text-align: left; float: left; padding: 0 0 1em 0; width: 254px; font-size: 1em; 
	@media only screen and (min-width: 768px) { }
	@media only screen and (min-width: 992px) {  float: right; width: auto; padding: 15px 20px 0 0; text-align: right; top: 0;

	}
	@media only screen and (min-width: 1200px) { }
	@media only screen and (min-width: 1400px) { }
		li { float: left; display: inline; padding: 0 0.3em; position: relative;
			@media only screen and (min-width: 992px) { width: auto; float: left ; }
			a { float: left; width: auto; margin: 0; padding: 0.2em 0 0.15em 0; color: $csfwhite; text-transform: uppercase; letter-spacing: 0.05em;
			@media only screen and (min-width: 992px) {
			
			&.img { padding: 0.05em 0 0.15em 0; margin: 0 0.3em; color: $csfwhite; }
			}
				&:hover { color: $csfred; }
				&:active { color: $csfred; }
				&.sel { color: $csfred;
				@media only screen and (min-width: 992px) { color: $csfred; }
				}
				.fa { font-size: 1.2em; }
			}
		&.langdesktop { display: none; font-size: 1em;
		@media only screen and (min-width: 992px) { display: block; }		
		}
		}
	}	
	#main { position: fixed; top: 0; right: -100%; text-align: left; width: 100%; height: 100%; margin: 0; padding: 0;  display: flex;
    justify-content: flex-end;
		float: left; letter-spacing: 0.05em; font-size: 1.6666em; font-weight: normal; font-style: normal; border: 0 dotted DodgerBlue; z-index: 201000; overflow: hidden;  overflow: scroll; -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */ font-family: $heavycn;
	@media only screen and (min-width: 768px) {  }
	@media only screen and (min-width: 992px) { position: static; top: auto; right: auto; height: auto; margin: 0; overflow: visible; font-size: 1.1em; padding: 25px 0 0 180px;  font-family: $heavy;
    
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	}
	@media only screen and (min-width: 1200px) { font-size: 1.4em; }
	@media only screen and (min-width: 1400px) { font-size: 1.6666em; }
	@media print { display: none; }
		
		a { text-decoration: none; }
		
		.langmobile { float: left; padding: 30px 15px 2em 15px; height: auto; display: block; font-family: $demicn; width: 100%; font-size: 0.7em;
		@media only screen and (min-width: 992px) { display: none; }		
			li { float: left; display: inline-block; padding: 0; margin: 0 0.5em 0 0;
				a { float: none; display: inline-block; width: auto; margin: 0; padding: 0.2em 0 0 0; color: $csfwhite; text-transform: uppercase; letter-spacing: 0.05em; 
					&:hover { color: $csfred; }
					&:active { color: $csfred; }
					&.sel { color: $csfred; }
				}
			}
		}
		
		#mainmenu, #sidemenu { float: left; margin: 0 0 0 0; padding: 0 20px 0 0; height: auto; display: block; font-weight: bold; width: 100%;
		@media only screen and (min-width: 992px) { margin: 0; padding: 0 20px 0 0; width: auto; }
		
			li { float: left; display: inline-block; padding: 0; width: 100%; position: relative;
			@media only screen and (min-width: 992px) { width: auto; text-align: left;}
				a { float: none; display: inline-block; width: auto; margin: 0; padding: 0.2em 15px 0 15px; color: $csfwhite; text-transform: uppercase; letter-spacing: 0.05em; line-height: 1.2em;
				@media only screen and (min-width: 992px) { color: $csfwhite; margin: 0 0.4em; padding: 2px 0 25px 0; }
				@media only screen and (min-width: 1200px) { padding: 2px 0 19px 0 }
				@media only screen and (min-width: 1400px) { padding: 2px 0 16px 0 }
					&:hover { color: $csfred; }
					&:active { color: $csfred; }
					&.sel { color: $csfred; }
				}
				ul { display: none; width: 100%; float: left; 
				@media only screen and (min-width: 992px) { float: none; width: 240px; position: absolute; display: none; top: 45px; left: 0px; width: auto; z-index: 10000; }
				@media only screen and (min-width: 1200px) { top: 45px; }
					li { display: inline; float: none; height: auto;
						a { width: 100%; color: $csfwhite; padding: 0.15em 15px 0.15em 15px;
						@media only screen and (min-width: 992px) { width: 270px; height: auto; border-bottom: 1px solid #ddd; padding: 0.3em 0.6em 0.3em 0.6em; font-size: 0.8em; background-color: $csfwhite; color: $csfgrey; font-family: $demi; text-transform: none;
						}
						&:hover { background-color: #333; color: $csfred; }
						}
					}
				}
				&:hover { color: $csfred;
					a { color: $csfred; }
					ul li {
						a { background-color: $csfwhite; color: $csfgrey;
						@media only screen and (min-width: 992px) { color: $csfblack; }
						&:hover { background-color: none; color: $csfred;
						@media only screen and (min-width: 992px) { background-color: $csfred; color: $csfwhite; }
						}
						}
						&.nav-item--is-current a { background-color: $csfred; color: $csfwhite; }
					}
					ul { display: block; }
				}
			&.nav-item--is-top-parent a { color: $csfred;
			@media only screen and (min-width: 992px) { color: $csfred; }
			}
			&.parent-selected a { color: #333; border-bottom: 1px solid $csfblack; }
			}
		}
		#sidemenu { float: left;
		@media only screen and (min-width: 992px) { float: right; margin: 0; padding: 0; width: auto; }
			li {
			@media only screen and (min-width: 992px) { }
				a { 
				@media only screen and (min-width: 992px) { margin: 0 0.2em; padding: 2px 10px; background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; }
					&:hover { color: $csfred;
					@media only screen and (min-width: 992px) { color: $csfcreme; }
					}
					&:active { color: $csfred;
					@media only screen and (min-width: 992px) { color: $csfcreme; }
					}
					&.sel { color: $csfred;
					@media only screen and (min-width: 992px) { color: $csfcreme; }
					}
				}
				ul { position: absolute; display: none; top: 40px; right: 0px; width: auto; z-index: 10000;
				@media only screen and (min-width: 992px) { width: 240px; }
					li { display: inline; float: none; height: auto;
						a {
						&:hover { background-color: #333; color: $csfred; }
						}
					}
				}
				&:hover {
					a { }
					ul li {
						a { 
						&:hover { }
						}
						&.nav-item--is-current a {  }
					}
					ul { }
				}
			&.nav-item--is-top-parent a { color: $csfwhite;
			@media only screen and (min-width: 992px) { color: $csfwhite; }
			&:hover { color: $csfcreme; }
			}
			&.parent-selected a {  }
			&#kb-link {
				a {
				@media only screen and (min-width: 992px) { background: url(../images/box-kb-beige.png); background-size: cover; color: #333;
				
				}
				}
			}
			}	
		}
		ul#mobilemeta { font-weight: normal; font-style: normal; font-family: $demicn; border: 0 dotted DodgerBlue; text-align: left; float: left; margin: 2em auto 0 auto; padding: 0 0 1em 0; width: 254px; font-size: 0.7em; display: block;
		@media only screen and (min-width: 768px) { }
		@media only screen and (min-width: 992px) { display: none; float: right; width: auto; padding: 20px 0 0 0; text-align: right; top: 0;
	
		}
			li { float: left; display: inline; padding: 0; position: relative; width: 100%;
				@media only screen and (min-width: 992px) { width: auto; float: left ; padding: 0 0.3em; }
				a { float: left; width: auto; margin: 0; padding: 0.2em 15px 0.15em 15px; color: $csfwhite; text-transform: uppercase; letter-spacing: 0.05em;
				@media only screen and (min-width: 992px) {
				
				&.img { padding: 0.05em 0 0.15em 0; margin: 0 0.3em; color: $csfwhite; }
				}
					&:hover { color: $csfred; }
					&:active { color: $csfred; }
					&.sel { color: $csfred;
					@media only screen and (min-width: 992px) { color: $csfred; }
					}
					.fa { font-size: 1.2em; }
				}
			&.socialmedia { margin: 0.5em 0 0 0;
				a { padding: 0.2em 5px 0.15em 15px; }
			}
			&.langdesktop { display: none; font-size: 1em;
			@media only screen and (min-width: 992px) { display: block; }		
			}
			}
		}
	}
}

	
.moodimagewrap { position: relative; width: 100%; overflow: visible; max-width: 100%; margin: 0 auto; border: 0 dotted DodgerBlue; min-height: 100px;
@media only screen and (min-width: 768px) { min-height: 100px;}

&.noimage { background: #000; margin-bottom: 114px; min-height: 41px;
@media only screen and (min-width: 768px) { background: #000; min-height: 0; height: 41px; }
@media only screen and (min-width: 1025px) { background: $csfwhite; }
}

section.slick-slide { height: auto; width: 100%; max-height: 864px; padding: 0; margin: 0 auto 0 auto;
	@media only screen and (min-width: 768px) { max-height: 864px; }
	@media only screen and (min-width: 992px) { max-height: 864px; margin: 0 auto 0 auto; }
	@media only screen and (min-width: 1400px) { max-height: none;}
			
		img { width: 100%; height: auto; }
		
		#moodimagecarousel { text-align: center; background: #333;
			.carousel-caption { /* position: absolute; width: 30%; height: 30%; top: 35%; left: 35%; */ float: left; position: relative; width: 100%; height: 70px; top: auto; left: auto; right: auto; bottom: 0; background: #333; margin: 0 0 0 0; padding: 10px;
			@media only screen and (min-width: 768px) { display: table; float: none; position: absolute; width: 25%; min-height: 80px; height: auto; top: 38%; left: 40%; bottom: 20px; padding: 1em 0.5em; background: rgba(0,0,0,0.8); font-size: 0.8em; }
			@media only screen and (min-width: 992px) { width: 20%; height: auto; top: 40%; left: 40%; bottom: 20px; padding: 20px 10px; background: rgba(0,0,0,0.8); font-size: 0.9em; }
			@media only screen and (min-width: 1200px) { width: 20%; height: auto; top: 42%; left: 40%; bottom: 20px; padding: 1em 0.5em; background: rgba(0,0,0,0.8); font-size: 1em; }
			@media only screen and (min-width: 2100px) { height: auto; top: 44%; }
			
				/* corr {
				@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; text-align: center; position: relative; }
				}*/
				.captiontext {
				@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; }
				}
				
				h1, p {  color: #fff; text-transform: uppercase; text-shadow: none; font-size: 1.5em; line-height: 1.2em; text-align: center;}
				p { font-size: 0.7em; letter-spacing: 0.1em; margin: 0.5em 0 0 0;
					a { color: #ddd; border-bottom: 1px solid #999;
					&:hover { color: #fff; border-bottom: 1px solid #fff; }
					}
				}
			
			&.seethrough { background: rgba(255,255,255,0.8);
				h1, p { color: $csfblack; }
				p {
					a { color: #333; border-bottom: 1px solid #666;
					&:hover { color: #000; border-bottom: 1px solid #000; }
					}
				}
			}
			}
			a.imagelink:hover {
				.carousel-caption { background: rgba(163,11,47,1); }
			}
			.carousel-indicators {
				li { margin: 1px 4px;
					&.active { margin: 0px 3px; }
				}
			}
		}
	}
}

#bookmobile { display: block; background: $csfred; text-align: center;
	a { color: $csfwhite; font-size: 2em; }
@media only screen and (min-width: 992px) { display: none; }	
}

#container { width: 100%; overflow: hidden;
@media only screen and (min-width: 768px) { background-repeat: repeat-y; background-attachment: fixed; background-size: cover; background-position: center top; }
}

#contentframe { position: relative; width: 100%; overflow: hidden; margin: 67px auto 0 auto; border: 0 dotted DodgerBlue;
@media only screen and (min-width: 480px) { margin: 71px auto 0 auto; }
@media only screen and (min-width: 768px) { padding: 0; background-color: rgba(255, 255, 255, 0); }
@media only screen and (min-width: 992px) { margin: 0 0 0 0; padding: 104px 0 0 0; /* padding: 0 0 285px 0;*/ }
	&.about { }
}

	.breadcrumbs { font-size: 1em; margin: 0 0 2em 0; padding: 0; text-align:center; list-style: none; line-height: 1.5em;
	@media only screen and (min-width: 768px) { font-size: 1.5em; }
		li { display: inline; margin: 0;
		&:before { content:"›"; padding: 0 0.4em 0 0.4em; }
			a { color: $csfblack;
			&:hover { color: $csfred; }	
			}
		&.home {
		&:before { content:""; }
		}
		}
	}
	
	main#content { float: none; max-width: 100%; margin: 0 auto; padding: 0; text-align: left; z-index: 0; min-height: 0; overflow: hidden; clear: both;
@media only screen and (min-width: 768px) { min-height: 1000px; padding: 0 0 130px 0;}
@media only screen and (min-width: 992px) { padding: 0 0 130px 0; }

	&.home { padding: 0; max-width: 100%; }
	
	div.wrap { margin: 0; padding: 0; overflow: hidden; }
	
	.block { float: left; width: 100%; margin: 0; overflow: hidden; border: 0 dotted DodgerBlue; }
	.projectblock { float: left; width: 100%; margin: 0; overflow: hidden; border: 0 dotted DodgerBlue; }

	/* #homepagenews { padding-top: 100px; padding-bottom: 100px; float: left; text-align: center; background-attachment: scroll !important;
	@media only screen and (min-width: 992px) {  }
	
		#voucher { background-color: $csfblack; color: $csfwhite; margin: 0 auto; padding: 1.5em 1em 1em 1em; float: none; margin: 20px auto; overflow: hidden;
			a { color: $csfwhite !important; float: left; width: 100%; }
			span { float: left; line-height: 1.3em; text-transform: uppercase; letter-spacing: 1px; font-weight: normal; font-size: 2em; margin: 0.15em 0 0 0;
		@media only screen and (min-width: 768px) { float: none; }
			}
			img { width: 75px; height: auto; float: right;
			@media only screen and (min-width: 768px) { margin: -6px 0 0 0; }
			}
		}
		h1 { float: none; margin: 0 auto; background-color: $csfred; color: $csfwhite; line-height: 1.3em; text-transform: uppercase; letter-spacing: 1px; font-weight: normal; font-style: normal; font-size: 2em; }
		.news { margin: 0 auto; padding: 1.5em 2em 1em 2em; float: none; text-align: left; border-bottom: 1px solid #ccc;
			h1 { color: $csfblack; background-color: transparent; line-height: 1.2em; text-transform: uppercase; letter-spacing: 1px;  margin: 0; font-size: 2em;
			@media only screen and (min-width: 768px) { font-size: 2em; }
			@media only screen and (min-width: 992px) { font-size: 2em; }
			}
			p { line-height: 160%; padding: 0.6em 0; }
			a { font-size: 1em; letter-spacing: 0em; color: $csfblack; text-transform: none; border-bottom: 1px solid $csfgrey;
				&.download { font-size: 1.33em; letter-spacing: 0.05em; text-transform: uppercase; color: $csfred; border: 0; }
			}
		}
	} */
	#homepagefeatures { padding: 0; 
		.item { position: relative;
			img { width: 100%; height: auto; }
			.carousel-caption { float: left; position: relative; width: 100%; height: 125px; top: 0; left: auto; right: auto; bottom: auto; background: $csfcreme; margin: 0 0 0 0; padding: 10px;
				@media only screen and (min-width: 768px) { display: table; float: none; position: absolute; width: 25%; min-height: 80px; height: auto; top: 38%; left: 40%; bottom: 20px; padding: 1em 0.5em; background: rgba(252,249,247,0.8); font-size: 0.8em; }
				@media only screen and (min-width: 992px) { width: 25%; height: auto; top: 40%; left: 38%; bottom: 20px; padding: 20px 10px; font-size: 0.9em; }
				@media only screen and (min-width: 1200px) { width: 25%; height: auto; top: 42%; left: 38%; bottom: 20px; padding: 3em 0.5em; font-size: 1em; }
				@media only screen and (min-width: 2100px) { height: auto; top: 44%; }
				
					/* corr {
					@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; text-align: center; position: relative; }
					}*/
					.captiontext {
					@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; }
					}
					
					h1, p {  color: $csfblack; text-transform: uppercase; text-shadow: none; font-size: 3.33em; line-height: 1.2em; text-align: center; }
					p { font-size: 2em; letter-spacing: 0.03em;margin: 0;
						a { color: $csfgrey; border-bottom: 1px solid #999;
						&:hover { color: $csfblack; border-bottom: 1px solid #fff; }
						}
					}
				
				&.seethrough { background: rgba(255,255,255,0.8);
					h1, p { color: $csfblack; }
					p {
						a { color: #333; border-bottom: 1px solid #666;
						&:hover { color: #000; border-bottom: 1px solid #000; }
						}
					}
				}
			}
		}
	}
	
	#subnav { font-size: 2em; font-weight: normal; font-style: normal; letter-spacing: 0.05em; padding: 1em 0;

	
	@media only screen and (min-width: 768px) { padding: 0 6px 0 15px; }
		ul { float: left; width: 100%; position: relative;
		border-radius: 2px;
	
			li {
				a { background-color: rgba(252,249,247,0.95); color: $csfblack; float: left; width: 100%; padding: 0.3em 5%; border-bottom: 1px solid #ddd;
					span { font-size: 0.7em; padding: 0 0 0 0.5em; }
					.icon { width: 30px; height: 30px; float: right; background-position: left top; background-repeat: no-repeat; background-size: 30px 60px; }
					&:hover { background-color: $csfcreme; color: $csfblack;
						.icon { background-position: left top; }
					}
				}
			&.selected {
				a { background-color: $csfred; color: $csfwhite;
					.icon { background-position: left -30px; }
				}
			}
			&.overview {
				a { background-color: rgba(252,249,247,0.95); color: $csfred;
					&:hover { background-color: $csfcreme; color: $csfblack; }
				}
				&.selected {
					a { background-color: $csfred; color: $csfwhite;
						&:hover { background-color: $csfred; color: $csfwhite; }
					}
				}
			}
			&.last {
				a {}
			}
			}
		}
	}
	
	#featuredimage { 
		img { width: 100%; height: auto; }
	}
	.nofeaturedimage { height: 100px }
	
	.articleintro {
		header#entry { text-align: center; position: relative; top: -12px; margin: 0 0 50px 0;
			h1 { font-size: 1.2em; background: url("/static/images/box-csf-black-big.png"); background-size: cover; display: inline; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 10px;
			@media only screen and (min-width: 481px) { font-size: 2em; }
			@media only screen and (min-width: 768px) { font-size: 3em; }
			@media only screen and (min-width: 992px) { font-size: 4em; }
			}
		}
	}
	
	.contentwrap { max-width: 1200px; margin: 0 auto; clear: both;
	&.textonly { max-width: 768px; overflow: hidden; }
	}
	
	article { margin: 0; overflow: visible; border: 0 dotted DodgerBlue; padding: 10px;
	border-radius: 2px; position: relative;
		
	@media only screen and (min-width: 768px) { margin: 0 0 0 0; /* margin-left: 25%; */ }
	@media only screen and (min-width: 992px) { padding: 0; }

		&.homeintro {
		@media only screen and (min-width: 768px) {  }
		@media only screen and (min-width: 1900px) { }
		
			.articletext { font-family: $demi;
				p { font-size: 1.6em; line-height: 1.3em; margin: 1em 0 0.5em 0; }
			@media only screen and (min-width: 992px) { font-size: 1.2em; padding: 70px 80px; }
			
			}
			
			#testimonialwrap {
			#testimonialcarousel { margin: 20px 0 0 0; min-height: 580px;
				.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev { top: 100px; }
				.carousel-inner {
					.item { min-height: 580px;
					@media only screen and (min-width: 768px) { min-height: 550px; }
					
						img { border-radius: 50%; max-width: 220px; margin: 0 auto; }
						.carousel-caption { position: static; color: $csfblack; text-align: center; padding: 1em 0; text-shadow: 0 0 0 rgba(0,0,0,0);
						@media only screen and (min-width: 481px) { padding: 1em 2em; }
						@media only screen and (min-width: 768px) { padding: 1em 6em; }
						@media only screen and (min-width: 992px) { padding: 1em 2em; text-align: left; }
							p { font-size: 1.2em; text-shadow: 0 0 0 rgba(0,0,0,0); font-family: $demi; line-height: 1.4em;
							@media only screen and (min-width: 768px) { font-size: 1.4em; }
							&.info { font-size: 1em; color: $csfblack; margin: 1em 0 0 0; font-family: $sans-serif; line-height: 1.4em;}
							}
							div { margin: 0.5em 0 1em 0; text-align: left; 
							&.info { line-height: 1.3em;
								ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em; text-align: center;
								@media only screen and (min-width: 768px) { text-indent: -0.85em; }
								@media only screen and (min-width: 992px) { text-indent: -0.6em; text-align: left; }
									li { line-height: 140%; padding: 0.1em 0; font-size: 0.9em; list-style: none;
										&:before { content: "\003E \0020"; }
									@media only screen and (min-width: 768px) { font-size: 1em; }
									@media only screen and (min-width: 992px) { font-size: 1em; }
									}
								}
							}
							}
							.support { text-align: center; margin: 40px 0 0 0;
								.btn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px; font-size: 0.8em; border: 0; white-space: normal;
								&:hover { color: $csfcreme; }
								}
							}
						}
					}
				}
			}
			.support { text-align: center; margin: 0 0 40px 0;
				.btn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px; font-size: 1.2em; border: 0; white-space: normal;
				&:hover { color: $csfcreme; }
				}
			}
			}
		}
		
		&.intro { width: 100%; padding: 15px 15px; /* padding: 50px 15px; */
		@media only screen and (min-width: 768px) { padding: 0 25% 50px 25% /* padding: 50px 25%;*/  }
		@media only screen and (min-width: 1900px) { padding: 0 25% 100px 25% /* padding: 50px 25%;*/  }
		
			h1 { padding: 0.5em 0 0 0;
			@media only screen and (min-width: 768px) { padding: 0; }
			}
			input { margin: 0 1em 1em 0;
			@media only screen and (min-width: 768px) { margin: 0 1em 0 0; }
			}
			form { margin: 0;
				.form-control { color: $csfblack; font-size: 17px; padding: 6px; }
			}
		}
		
	
		&.pagenonavi { margin: 0 0 0 0;
		
		@media only screen and (min-width:992px) { margin: 0 0 0 25%; }
			
		}


		&.introdirect { width: 100%; padding: 50px 15px;
		@media only screen and (min-width: 768px) { padding: 50px 25% 50px 25%; }
		h1 { padding: 0; }
		}
		
		&#unsere-partner, &#our-partners {
			section.articletext {
				p { text-align: center;
					a {  }
					img {  }
				}
			}
		}
		
		section.articletext { padding: 15px 0;
		@media only screen and (min-width: 768px) { padding: 0 50px 50px; }
		&.intro { 
		@media only screen and (min-width: 768px) { padding: 50px 50px 0 50px;
			h1 { margin: 5px 0 20px 0; }
		}
		}
		&.mainpage {
			h1 { color: $csfred; line-height: 1em; 
			@media only screen and (min-width: 768px) { text-align: center; margin: 5px auto 50px auto; font-size: 4em; }
			@media only screen and (min-width: 992px) { font-size: 4.6666em; }
			@media only screen and (min-width: 1200px) { width: 66%; }
			}
		}

		
			h1, h2, h3 { position: relative; padding: 0; color: $csfblack; line-height: 1.3em; text-transform: uppercase; letter-spacing: 1px; font-weight: normal; font-style: normal; font-family: $demi; }
			
			h1 { margin: 0 0 1em 0; padding: 0; font-size: 3em; color: $csfblack; line-height: 1.2em; text-align: left; width: 100%; letter-spacing: 0.02em; line-height: 1.2em; text-align: center; font-family: $bold;
			img.titleicon { height: 75px; width: auto; margin: 0 0 25px 0; }
			span { font-size: 0.7em; }
			@media only screen and (min-width: 768px) { text-align: center; margin: 5px 0 50px 0; font-size: 4em; }
			@media only screen and (min-width: 992px) { font-size: 4.6666em; }
			}
			
			h2 { margin: 1em 0 0 0; font-size: 2em; color: #111; line-height: 1.3em;
			@media only screen and (min-width: 768px) { font-size: 2em; }
			@media only screen and (min-width: 992px) { font-size: 2em; }
	
			}
				
			h3 { margin: 1em 0; font-size: 1.2em; color: #333; text-transform: uppercase; line-height: 1em; }
			
			&.lead {
				p { font-family: $demi; font-size: 1.6em; line-height: 1.3em; margin: 1em 0 0.5em 0;
				}
			@media only screen and (min-width: 768px) { font-size: 1.2em; }
			}
			
			p { margin: 0 0 1em 0; width: auto; font-size: 1em; color: $csfblack; padding-bottom: 0.1em; letter-spacing: 0.01em; line-height: 170%;
			@media only screen and (max-width: 767px) { @include hyphenate(); }
			@media only screen and (min-width: 768px) { font-size: 1.2em; }
			@media only screen and (min-width: 992px) { font-size: 1.2em; }
			
				&.lead { font-family: $demi; font-size: 1.6em; line-height: 1.3em; margin: 1em 0 0.5em 0;
				@media only screen and (min-width: 992px) { font-size: 1.2em; padding: 70px 80px; }
				}
				&.bodylead { font-family: $demi; font-size: 1.4em; line-height: 150%; margin: 1em 0 0.5em 0;
				@media only screen and (min-width: 768px) { font-size: 1.9em; padding: 0 0; }
				}
			
				a { color: $csfblack; text-decoration: none !important; font-weight: normal; text-transform: none; border-bottom: 1px solid $csfgrey;
					&:hover { color: $csfgrey; text-decoration: none; }
					&:active { color: $csfred !important; }
					&.sel { color: $csfblack; }
				}
			}
			ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em; text-align: left;
				@media only screen and (min-width: 768px) { text-indent: -0.85em; }
				@media only screen and (min-width: 992px) { text-indent: -1em; }
					li { line-height: 140%; padding: 0.1em 0; font-size: 0.9em; list-style: none;
						&:before { content: "\003E \0020"; }
					@media only screen and (min-width: 768px) { font-size: 1em; }
					@media only screen and (min-width: 992px) { font-size: 1em; }
					}
				}
			iframe { }
			
			.donationtestimonial { background-color: $csfcreme; padding: 15px; margin: 30px 0; text-align: center; overflow: hidden;
			@media only screen and (min-width: 768px) { padding: 30px; }
				img { border-radius: 50%; margin: 0 auto; }
				.testimonial { text-align: left; font-family: $sans-serif;
					p { font-size: 1.5em; line-height: 1.3em;
					@media only screen and (min-width: 768px) { font-size: 1.6em; }
					}
				}
				.testimonialinfo {
					h3 { text-transform: uppercase; font-family: $bold; font-size: 1.1em; margin: 1.5em 0 0 0; }
					p { font-size: 1.4em; line-height: 1.2em; margin: 1em 0;
					@media only screen and (min-width: 768px) { font-size: 1.6em; }
					}
					div.info { font-size: 1em; color: $csfblack; margin: 1em 0 3em 0; font-family: $sans-serif; line-height: 1.4em;
						ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em;
						@media only screen and (min-width: 768px) { text-indent: -0.85em; }
						@media only screen and (min-width: 992px) { text-indent: -1em; }
							li { line-height: 140%; padding: 0.1em 0; font-size: 0.9em; list-style: none;
								&:before { content: "\003E \0020"; }
							@media only screen and (min-width: 768px) { font-size: 1em; }
							@media only screen and (min-width: 992px) { font-size: 1em; }
							}
						}
					}
				}
				
				.btn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px; font-size: 1.1em;
				@media only screen and (min-width: 768px) { float: none; }
				@media only screen and (min-width: 992px) { float: none; }
				&:hover { color: $csfcreme; }
				}
			}

		}
		
		section.features { background: $csfcreme; overflow: hidden; border-radius: 0 0 2px 2px;
			article { margin: 0; padding: 15px 15px; background: transparent;
		
				
				h1 { margin: 0 0 1em 0; padding: 0; font-size: 2em; color: $csfblack; line-height: 1.2em; text-align: left; width: 100%; letter-spacing: 0.02em; text-transform: uppercase; }
				h2 { margin: 1em 0 0 0; font-size: 1.5em; color: #111; line-height: 1.3em; text-transform: uppercase; }
				ul { color: $csfblack; margin: 1em 0em 1em 1em; text-indent: -0.9em;
					li { line-height: 160%; padding: 0.25em 0 0.25em 0; font-size: 1.2em; list-style-image: url(https://www.cuisinesansfrontieres.ch/library/images/red-square.png);
						a { font-size: 1.3333em; letter-spacing: 0.05em; }
					}
				}
				p { margin: 1em 0 0 0; width: auto; font-size: 0.9em; line-height: 180%; color: $csfblack; padding-bottom: 0.1em;
				@media only screen and (min-width: 768px) { font-size: 0.9em; }
				@media only screen and (min-width: 992px) { font-size: 0.9em; }
				
					&.lead { margin: 0.2em 0 0.5em 0; font-size: 1.4em; font-weight: 200; line-height: 160%; }
					a { color: $csfblack; text-decoration: none !important; text-transform: none; border-bottom: 1px solid $csfgrey;
						&:hover { color: #000; text-decoration: none; }
						&:active { color: $csfred !important; }
						&.sel { color: $csfblack; }
					}
				}
			}
			article + article { border-top: 1px solid #ddd; }
			.buttonbox { text-align: center; width: 100%;
				.button { color: $csfred !important; border: 1px solid $csfred !important; padding: 0.3em 3em; font-size: 1.5em; float: none; margin: 1em 0 2em 0; letter-spacing: 0.05em; white-space: nowrap; border-radius: 2px; text-align: center; display: inline-block;
					&:hover { color: #000 !important; border: 1px solid #000 !important; }
				}
			}
		}
			
		aside.articleimages {
		@media only screen and (min-width: 768px) {  }
			
			img { width: 100%; height: auto; border-radius: 2px 2px 0 0; margin: 0 0 25px 0; }
			.carousel { text-align: center; background: #333; overflow: hidden;
				.carousel-caption { float: left; position: relative; width: 100%; height: auto; top: auto; left: auto; right: auto; bottom: 0; background: #333; margin: 0 0 0 0; padding: 0.6em 1em 1em 1em; 
				@media only screen and (min-width: 768px) { display: table; position: absolute; background: rgba(0,0,0,0.8); padding: 1em 0.5em;; width: 36%; min-height: 80px; height: auto; top: 38%; left: 32%; font-size: 0.7em; line-height: 1.4em; }
				@media only screen and (min-width: 992px) { width: 36%; height: auto; top: 40%; left: 32%; font-size: 1em; line-height: 1.3em;}
				@media only screen and (min-width: 1200px) { width: 36%; height: auto; top: 42%; left: 32%; font-size: 1.1em; line-height: 1.3em;}
				@media only screen and (min-width: 2100px) { width: 30%; height: auto; top: 44%; left: 35%; }
					
					.captiontext {
					@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; }
					}
					
					h1, p {  color: #fff; text-transform: uppercase; text-shadow: none; font-size: 1em; line-height: 1.5em; margin: 0; padding: 0; text-align: center; }
					p { font-size: 0.7em; letter-spacing: 0.1em;
						a { color: #ddd; border-bottom: 1px solid #999;
						&:hover { color: #fff; border-bottom: 1px solid #fff; }
						}
					}
					img { background: none; width: 40%; max-width: 230px; height: auto;
					@media only screen and (min-width: 768px) { width: 100%; max-width: 230px; height: auto; }
					}
				&.seethrough { background: rgba(255,255,255,0.8);
					h1, p { color: $csfblack; }
					p {
						a { color: #333; border-bottom: 1px solid #666;
						&:hover { color: #000; border-bottom: 1px solid #000; }
						}
					}
				}
				}
				a.imagelink:hover {
					.carousel-caption { background: rgba(163,11,47,1); }
				}
				.carousel-indicators {
					li { margin: 1px 4px;
						&.active { margin: 0px 3px; }
					}
				}
			}
		}
		
		&.feature { margin-bottom: 20px;
		@media only screen and (min-width: 768px) { margin: 50px 16.6666%; }
		
			.articletext { text-align: left;
				.form-group {   display: inline-block; margin-bottom: 0; vertical-align: middle;
					input { margin-bottom: 1em; }
				}
		
			}
			
			aside.articleimages {
				img { width: 100%; height: auto; }
				.carousel { text-align: center; background: #333;
					.carousel-caption { float: left; position: relative; width: 100%; height: auto; top: auto; left: auto; right: auto; bottom: 0; background: #333; margin: 0 0 0 0; padding: 1em;
					@media only screen and (min-width: 768px) { display: table; position: absolute; padding: 0.5em; background: rgba(0,0,0,0.8); color: #fff; width: 36%; min-height: 80px; height: auto; top: 38%; left: 32%; font-size: 0.7em; line-height: 1.4em; }
					@media only screen and (min-width: 992px) { width: 30%; height: auto; top: 40%; left: 35%; font-size: 1em; line-height: 1.3em;}
					@media only screen and (min-width: 1200px) { width: 30%; height: auto; top: 42%; left: 35%; font-size: 1.1em; line-height: 1.3em;}
					@media only screen and (min-width: 2100px) { width: 30%; height: auto; top: 44%; left: 35%; }

						.captiontext {
						@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; }
						}
					
					h1, p {  color: #fff; text-transform: uppercase; text-shadow: none; font-size: 1.2em; line-height: 1.5em; text-align: center; }
						p { font-size: 0.7em; letter-spacing: 0.1em;
							a { color: #ddd; border-bottom: 1px solid #999;
								&:hover { color: #fff; border-bottom: 1px solid #fff; }
							}
						&.date { margin: 0.5em 0 0 0; }
						}
					
					&.seethrough { background: rgba(255,255,255,0.8);
						h1, p { color: $csfblack; }
						
						
					}
					}
					a.imagelink:hover {
						.carousel-caption { background: rgba(163,11,47,1); }
					}
				}
			}
		}
		
		&.gallerythumbs { margin-bottom: 20px;
		@media only screen and (min-width: 992px) { margin: 50px 16.6666%; }
		
			.articletext { width: 100%; padding: 30px 0; text-align: center;
				.form-group {   display: inline-block; margin-bottom: 0; vertical-align: middle;
					input { margin-bottom: 1em; }
				}
			@media only screen and (min-width: 992px) { padding: 26px 25% 0 25%; }
			}
			
			aside.articleimages { padding: 0 5px;
				figure { padding: 5px; text-align: center;
					img { border: 0 solid $csfwhite; width: 100%; height: auto; }
				}
			}
		}

		&.directions { 
			
			.map {
			@media only screen and (min-width: 768px) { }
			
				#map_canvas { width: 100%; overflow: hidden; position: relative; padding-bottom: 66.66%; height: 0; overflow: hidden;
				@media only screen and (min-width: 480px) { }
				@media only screen and (min-width: 768px) { }
				
					img {
						max-width: none;
					}
					#markertext { text-align: center;
						h1 { font-size: 1.3em; margin: 0 0 0 0; padding: 0; text-align: center; }
						p { font-size: 1.1em; margin-bottom: 0; }
					}		
				}
				#address { position: relative; top: 0; right: 0; background: #333; width: 100%; height: 0; padding: 45% 0 55% 0; text-align: center; z-index: 9000; float: none; margin: 0 auto 2em auto; font-size: 1.3em; 
				@media only screen and (min-width: 320px) { width: 250px; height: 190px; padding: 20px 0; font-size: 1.4em; }
				@media only screen and (min-width: 768px) { float: none; width: 270px; height: 190px; padding: 30px 0; margin: 40px auto; overflow: hidden; font-size: 1em; }
				@media only screen and (min-width: 992px) { position: absolute; float: none; top: auto; right: 26%; bottom: 90px; width: 240px; height: 155px; padding: 10px 0; font-size: 1.1em; margin: 0 20px 0 0; }
				
					h1 { font-size: 1.2em; margin: 0; color: #fff; text-align: center !important;
						span { padding: 0 0.1em; }
					}
					p { margin: 0.2em 0 0 0; font-size: 0.8em; color: #fff;
						a { border: 0; color: #eee;
							&:hover { color: #fff; border-bottom: 1px solid #fff; background: none;}
						}
					}
				}
			}
		}
		&.mediadownloads { width: 100%; padding: 15px 15px 0 15px;
		@media only screen and (min-width: 768px) { padding: 0 25% 30px 25%; }
		
			h1 { text-transform: none;  font-size: 1.2em; border: 0;}

				h2 { font-size: 0.8em; margin: 0.5em 0; }
				p { font-size: 0.8em; margin: 0.5em 0;
					a { color: #333; border-bottom: 1px solid #666;
					&:hover { color: #000; border-bottom: 1px solid #000; }
					}
				}
				ul { list-style-type: none; font-size: 1.2em; margin-left: 0; text-indent: 0;
					li { padding: 0.5em 0;
						a { color: #333; border-bottom: 1px solid #666;
							&:hover { color: #000; border-bottom: 1px solid #000; }
						}
					&:before { content: none !important; }
					}
				}
		}
	}
	
	section#projektueberblick {
		h1 { font-size: 3em; }
		.projekt { float: left; display: inline; margin: 0 0 40px 0; border: 0px dotted red;
		@media only screen and (min-width: 992px) { margin: 0 0 50px 0; }

			a.trigger { display: block; position: relative; width: 100%; height: auto; box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
				
				header { position: absolute; top: -15px; width: 100%; text-align: center; z-index: 1000;
					h2 { margin: 0; font-size: 2em; font-weight: normal; font-style: normal; color: #fff; background: url("/assets/static/images/box-csf-black-big.png"); background-size: cover; display: inline; padding: 2px 10px; font-family: $bold; text-transform: uppercase;
					@media only screen and (min-width: 768px) { font-size: 2em; }
					}
					img { width: 116px; height: auto;top: 35px; right: 5px; position: absolute; }
				}
			
				img { width: 100%; height: auto; vertical-align: top; border: 0; opacity: 1; 
				@media only screen and (min-width: 992px) { opacity: 1; }
				}
			
			.projektinfo { position: relative; width: 100%; height: auto; z-index: 100; background: $csfwhite; opacity: 1; top: 0px; left: 0px; right: 0px; bottom: 0px; text-align: center; float: left; padding: 0.8em 0 1em 0; margin-right: 15px;
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
			@media only screen and (min-width: 992px) { position: absolute; height: 100%; opacity: 0; }
				
					.projekttext { float: left; position: relative; width: 100%; bottom: auto; margin-bottom: 0; overflow: visible; padding: 0 5px;
					@media only screen and (min-width: 992px) { position: absolute; width: 100%; bottom: 50%; margin-bottom: -7px; overflow: hidden; padding: 20px 10px 5px 10px; }
					@media only screen and (min-width: 1200px) { position: absolute; width: 100%; bottom: 50%; margin-bottom: -15px; overflow: hidden; padding: 20px 20px 10px 20px; }
					
						h1 { float: none; width: auto; height: auto; margin: 0.2em 0 0 0; font-size: 3em; font-weight: normal; font-style: normal; color: $csfwhite; text-transform: uppercase;
						@media only screen and (min-width: 992px) { font-size: 3em; }
						@media only screen and (min-width: 1200px) { font-size: 3em; }
						}
						h2 { margin: 0; font-size: 2em; font-weight: normal; font-style: normal; color: #fff; background: url("/assets/static/images/box-csf-black-big.png"); background-size: cover; 
						@media only screen and (min-width: 768px) { font-size: 2em; }

						}
						p { color: $csfred; font-size: 1.5em; }
						.cats { color: #fff; font-size: 0.8em; float: none; margin: 0; }
					}
				}
				&:hover {
					header {
						h2 { background: url(../images/box-csf-red-big.png); background-size: cover; display: inline; }
						img { display: none; }
					}
					.projektinfo {	
					@media only screen and (min-width: 992px) { opacity: 1; }
						.projekttext {
							p { color: $csfred; }
						}
					}
				}
			}	
		}
	}
	section#mitmachueberblick {
		h1 { font-size: 3em; }
		.mitmachen { float: left; display: inline; margin: 0 0 40px 0; border: 0px dotted red; min-height: 600px;
		@media only screen and (min-width: 992px) { margin: 0 0 50px 0; min-height: auto; }

			a.trigger { display: block; position: relative; width: 100%; height: auto; box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
				
				header { position: absolute; top: -15px; width: 100%; text-align: center; z-index: 1000;
					h2 { margin: 0; font-size: 1.5em; font-weight: normal; font-style: normal; color: #fff; background: url("/assets/static/images/box-csf-black-big.png"); background-size: cover; display: inline; padding: 2px 10px; font-family: $bold; text-transform: uppercase;
					@media only screen and (min-width: 768px) { font-size: 1.5em; }
					}
				}
			
				img { width: 100%; height: auto; vertical-align: top; border: 0; opacity: 1; 
				@media only screen and (min-width: 992px) { opacity: 1; }
				}
			
			.mitmachinfo { position: relative; width: 100%; height: auto; z-index: 100; background: $csfwhite; opacity: 1; top: 0px; left: 0px; right: 0px; bottom: 0px; text-align: center; float: left; padding: 0.8em 0 1em 0; margin-right: 15px;
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
			@media only screen and (min-width: 992px) { position: absolute; height: 100%; opacity: 0; }
				
					.mitmachtext { float: left; position: relative; width: 100%; bottom: auto; margin-bottom: 0; overflow: visible; padding: 0 5px;
					@media only screen and (min-width: 992px) { position: absolute; width: 100%; bottom: 50%; margin-bottom: -7%; overflow: hidden; padding: 20px 10px 5px 10px; }
					@media only screen and (min-width: 1200px) { position: absolute; width: 100%; bottom: 50%; margin-bottom: -15%; overflow: hidden; padding: 20px 20px 10px 20px; }
					
						h1 { float: none; width: auto; height: auto; margin: 0.2em 0 0 0; font-size: 3em; font-weight: normal; font-style: normal; color: $csfwhite; text-transform: uppercase;
						@media only screen and (min-width: 992px) { font-size: 3em; }
						@media only screen and (min-width: 1200px) { font-size: 3em; }
						}
						h2 { margin: 0; font-size: 1.5em; font-weight: normal; font-style: normal; color: #fff; background: url("/assets/static/images/box-csf-black-big.png"); background-size: cover; 
						@media only screen and (min-width: 768px) { font-size: 1.5em; }

						}
						p { color: $csfred; font-size: 1.5em; line-height: 1.3em; }
						.cats { color: #fff; font-size: 0.8em; float: none; margin: 0; }
					}
				}
				&:hover {
					header {
						h2 { background: url(../images/box-csf-red-big.png); background-size: cover; display: inline; }
					}
					.mitmachinfo {	
					@media only screen and (min-width: 992px) { opacity: 1; }
						.mitmachtext {
							p { color: $csfred; }
						}
					}
				}
			}	
		}
	}
	
	header.hometitle {
			h1 { font-family: $demi; background: none; text-transform: none; font-size: 1.6em; margin: 2em 0 0 0;
			@media only screen and (min-width: 992px) { font-size: 3em; }
			}
		&.important { text-align: center; position: relative;  top: 0; margin: 0 auto;; z-index: 1;
			@media only screen and (min-width: 992px) { margin: 0 auto; }
			h1 { font-size: 1.6em; background: url("/assets/static/images/box-csf-black-big.png"); background-size: cover; display: inline; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px;
			@media only screen and (min-width: 992px) { font-size: 3em; }				
			}
		}
	}
	
	.tableinfos { position: static; float: left; z-index: 1; background-color: $csfwhite; margin: 40px 0 40px 0; padding: 10px; text-align: left;
	@media only screen and (min-width: 1250px) { padding: 20px; /*position: absolute; top: 100px; right: 50px; background: white; width: 33%; */ }
		h3 { font-family: $heavy; font-size: 2em; margin: 0 0 1em 0; }
		th { font-family: $demi; padding: 0 1.5em 0.5em 0; }
		th { padding: 0 1.5em 0.5em 0; }
		td { line-height: 1.3em; padding: 0 1.5em 0.5em 0; @include hyphenate(); }
	}
		
	.mapcontainer { position: relative; padding: 0 0 0 0; text-align: center;
		
		.markerinfo { overflow: hidden;
			.h1 { margin: 0.5em 0; font-size: 1.5em; font-family: $heavy; text-transform: uppercase; color: $csfred; }
		}
	}
	
	#donationbutton { text-align: center; margin: 0 auto; padding: 0 0 80px 0;
		.donationbtn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; color: $csfwhite; text-transform: uppercase; font-family: $heavy; padding: 12px 30px; font-size: 1.4em; margin: -15px; z-index: 10;
		&:hover { color: $csfcreme; }
		}
	}
	
	#newsletter { background-color: $csfcreme; background-image: url(https://www.cuisinesansfrontieres.ch/library/images/newsletter-map-blurred.jpg); background-size: cover; background-repeat: no-repeat; padding: 80px 0;
		.contentwrap { text-align: center;
		@media only screen and (min-width: 768px) { text-align: left }
		}
		img { width: 294px; height: auto; float: none;
		@media only screen and (min-width: 768px) { float: left; }
		}
		p.lead { font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 0.5em 0 1em 0;; line-height: 1.3em;
			img { width: 147px; height: auto; float: right; }
		}
		.form-group { padding: 0; margin-bottom: 10px;
		@media only screen and (min-width: 768px) { padding: 0 10px 0 0; }
			.form-control { height: 45px; }
		}
		.indicates-required { text-align: left; margin: 10px 0 0 0; }
		.btn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px; font-size: 1.2em; border: 0; white-space: normal; float: right; margin: 10px 0 0 0;
		&:hover { color: $csfcreme; }
		}
	}
	
	section#newscontainer {
		header {
			h1 { font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 2em 0 0 0; }
		}
		.news { background: $csfwhite; box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); margin: 60px 0 0 0;
			header {
				h1 { text-align: left; font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 15px 15px 30px;
				@media only screen and (min-width: 768px) { margin: 20px 30px 30px; }
				@media only screen and (min-width: 992px) { margin: 2em 3em; }
				}
			}
			.newsimages { padding-left: 0;
				img { background: none; margin: 0 0 0 0; padding: 0 0 15px 15px;
				@media only screen and (min-width: 992px) { padding: 0 30px 0 0; }	
				}
				img + img {
				@media only screen and (min-width: 992px) { padding: 30px 30px 0 0; }						
				}
			}
			.articletext { padding: 15px 0;
			@media only screen and (min-width: 768px) { padding: 0 50px 50px; }
			
				.locationdate { margin: 0; }
				.projectlink { float: right; font-family: $bold; color: $csfred; border: 0; text-transform: uppercase;
				&:hover { color: $csfblack; }
				}
			}
		&.media {
			.reportinfo { font-size: 1.2em; line-height: 1.3em; padding: 15px;
			@media only screen and (min-width: 768px) {	padding: 2em; }
				p {
					strong { font-family: $bold; font-size: 0.9em; text-transform: uppercase; letter-spacing: 0.05em; }
				}
			}
			.newsimages {
				img { background: none; margin: 0 0 25px 0; }
			}
			.articletext { margin: 0; padding: 15px 0;
			@media only screen and (min-width: 992px) { margin: 2.5em 0; padding: 0 1em 0 0; }
			
				h1 { font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 0 0 0.5em 0; text-align: left; }
				.locationdate { margin: 0; }
				.projectlink { float: right; font-family: $bold; color: $csfred; border: 0; text-transform: uppercase;
				&:hover { color: $csfblack; }
				}
			}
		}
		}
	}
	section#homepagenews, section#relatednews { padding-top: 100px; padding-bottom: 100px;
		header {
			h1 { font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 3em 0 0 0; }
		&.important { text-align: center; position: relative; top: -12px; margin: 0 0 0 0;
			h1 { font-size: 3em; background: url("/assets/static/images/box-csf-black-big.png"); background-size: cover; display: inline; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px; }
		}
		} 
		.news { background: $csfwhite; box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); margin: 60px 0 0 0;
			header {
				h1 { text-align: left; font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 0.8em 0 0.3em 0;
				@media only screen and (min-width: 768px) { margin: 0.8em 0 0.3em 0; }
				@media only screen and (min-width: 992px) { margin: 0.8em 0 0.3em 0; }
				}
			}
			.newsimages { padding: 0 15px 0 0; 
				img { background: none; margin: 0 0 0 0; padding: 15px 0 15px 15px;
				@media only screen and (min-width: 992px) { padding: 0 30px 0 0; }	
				}
				img + img {
				@media only screen and (min-width: 992px) { padding: 30px 30px 0 0; }						
				}
			}
			.articletext { padding: 15px;
			@media only screen and (min-width: 768px) { padding: 15px; }
			
				.locationdate { margin: 0; }
				.moreinfo { font-family: $bold; color: $csfred; border: 0; text-transform: uppercase;
				&:hover { color: $csfblack; }
				}
			}
			 
		&.media {
			.reportinfo { font-size: 1.2em; line-height: 1.3em;
			@media only screen and (min-width: 768px) {	padding: 2em; }
				p {
					strong { font-family: $bold; font-size: 0.9em; text-transform: uppercase; letter-spacing: 0.05em; }
				}
			}
			.newsimages {
				img { background: none; margin: 0 0 0 0; }
			}
			.articletext { margin: 0; padding: 15px;
			@media only screen and (min-width: 992px) { margin: 2.5em 0; padding: 0 1em 0 0; }
			
				h1 { font-family: $demi; background: none; text-transform: none; font-size: 2em; margin: 0 0 0.5em 0; text-align: left; }
				.locationdate { margin: 0; }
			}
		}
		}
	}

	#textprojectmap {
		.articletext { font-family: $demi;
			p { font-size: 1.5em; line-height: 1.4em; margin: 0 0 0.5em 0; font-size: 1.5em;
			@media only screen and (min-width: 768px) { font-size: 1.5em; }
			@media only screen and (min-width: 992px) { font-size: 2.2em; }
			}
		}
	}
	
	section.homepagefeature { overflow: hidden; border-radius: 0 0 2px 2px; width: 100%; padding-top: 60px;
		article.feature { margin: 0; padding: 33px 15px 75px; background: transparent;
			&:last-child {
				padding: 33px 15px 0;
			}

			
			aside.articleimages {
		@media only screen and (min-width: 768px) { padding-left: 0; }
			
				img { border-radius: 0; margin: 0 0 25px 0;
				@media only screen and (min-width: 768px) { width: 100%; height: auto; }
				}
			}
			
			.articletext { font-family: $demi;
				h1 { margin: 0 0 1em 0; padding: 0; font-size: 2em; color: $csfblack; line-height: 1.2em; text-align: left; width: 100%; letter-spacing: 0.02em; text-transform: uppercase; }
				h2 { margin: 1em 0 0 0; font-size: 1.5em; color: #111; line-height: 1.3em; text-transform: uppercase; }
				ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em;
					@media only screen and (min-width: 768px) { text-indent: -0.85em; }
					@media only screen and (min-width: 992px) { text-indent: -1em; }
						li { line-height: 140%; padding: 0.1em 0; font-size: 1.2em; list-style: none;
							&:before { content: "\003E \0020"; }
						}
					}
				p { font-size: 1.5em; line-height: 1.4em; margin: 0 0 1em 0; width: auto; color: $csfblack; padding-bottom: 0.1em;
				@media only screen and (min-width: 768px) { }
				@media only screen and (min-width: 992px) { }
				
				&.lead { margin: 0.2em 0 0.5em 0; font-size: 1.4em; font-weight: 200; line-height: 160%; }
				&.info { font-size: 1.5em; color: $csfblack; margin: 2em 0 4em; padding: 0; font-family: $sans-serif; line-height: 1.4em;}
					a { color: $csfblack; text-decoration: none !important; text-transform: none; border-bottom: 1px solid $csfgrey;
						&:hover { color: #000; text-decoration: none; }
						&:active { color: $csfred !important; }
						&.sel { color: $csfblack; }
						&.btn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; font-family: $heavy; color: $csfwhite; text-transform: uppercase; font-size: 0.6em; }
					}
				}
			}
		}
		article + article { border-top: 1px solid #ddd; }
		.buttonbox { text-align: center; width: 100%;
			.button { color: $csfred !important; border: 1px solid $csfred !important; padding: 0.3em 3em; font-size: 1.5em; float: none; margin: 1em 0 2em 0; letter-spacing: 0.05em; white-space: nowrap; border-radius: 2px; text-align: center; display: inline-block;
				&:hover { color: #000 !important; border: 1px solid #000 !important; }
			}
		}
	}

	
	/* ---- button ---- */

.button {
  display: inline-block;
  padding: 10px 18px;
  margin-bottom: 10px;
  background: $csfcreme;
  border: none;
  border-radius: 1px;
  color: $csfblack;
  font-family: $heavy;
  letter-spacing: 0.05em;
  font-size: 16px;
  cursor: pointer;
}

.button:hover {
  background-color: $csfred;
  color: $csfwhite;
}

.button:active,
.button.is-checked {
  background-color: $csfred;
}

.button.is-checked {
  color: white;
}

.button:active {
}

/* ---- button-group ---- */

.button-group:after {
  content: '';
  display: block;
  clear: both;
}

.button-group .button {
  float: NONE;
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
}

.button-group .button:first-child { border-radius: 0 0 0 0; }
.button-group .button:last-child { border-radius: 0 0 0 0; }

	.contentfilter { text-align: center; }

	#personen { padding: 0;
		.person { height: auto; padding: 8px; 
		@media only screen and (min-width: 481px) and (max-width: 767px) { width: 50%;
		&.featured { width: 100%; }
		}
		@media only screen and (min-width: 768px) { height: auto; }
		@media only screen and (min-width: 992px) { height: auto; }
			.personcontent { background: $csfwhite; box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); padding: 15px 20px; height: auto; text-align: center; overflow: hidden;
			@media only screen and (min-width: 768px) { height: auto; }
			@media only screen and (min-width: 992px) { height: auto; }
				
				.featuredtestimonial { font-size: 1.5em; line-height: 1.3em; /* min-height: 250px; */ }
				img { border-radius: 50%; max-height: 152px; }
				h3 { text-transform: uppercase; font-family: $bold; font-size: 1.1em; margin: 1.5em 0 0 0; text-align: left; }
				div { margin: 1em 0 1em 0; text-align: left; 
				&.info { line-height: 1.3em;
					ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em;
					@media only screen and (min-width: 768px) { text-indent: -0.85em; }
					@media only screen and (min-width: 992px) { text-indent: -1em; }
						li { line-height: 140%; padding: 0.1em 0; font-size: 0.9em; list-style: none;
							&:before { content: "\003E \0020"; }
						@media only screen and (min-width: 768px) { font-size: 1em; }
						@media only screen and (min-width: 992px) { font-size: 1em; }
						}
					}
				}
				}
				a.btn { background-color: $csfred; width: 100%; background: url(../images/box-kb-beige.png); background-size: cover; font-family: $bold; color: $csfblack; text-transform: uppercase; font-size: 0.9em;
				&:hover { color: $csfgrey; }
				}
			}
		&.featured { width: inherit + 200px;
			.personcontent {
				img { border-radius: 50%; max-height: 250px; }
			}
		}
		}
	}
	
	div.pagination { width: 100%; margin: 0 0 0 0; padding: 15px 15px; font-size: 1.4em; font-family: $sans-serif; text-align: right;
	@media only screen and (min-width: 768px) {  }
	&#paginatebottom { margin: 40px 0 0 0;  }
	}
	
	section.list { padding: 0; margin-top: 0; 
	@media only screen and (min-width: 768px) { }
	
		article { padding: 0; margin: 0;
			.articleimages {
				img { width: 100%; height: auto; background: #ccc; }
			}
			.articletext {
				h1 {  font-size: 1em; border: 0; line-height: 1.3em; }
				h2 {  font-size: 1em; }
				p {}

				ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em;
					@media only screen and (min-width: 768px) { text-indent: -0.85em; }
					@media only screen and (min-width: 992px) { text-indent: -1em; }
						li { line-height: 140%; padding: 0.1em 0; font-size: 0.9em; list-style: none;
							&:before { content: "\003E \0020"; }
						@media only screen and (min-width: 768px) { font-size: 1em; }
						@media only screen and (min-width: 992px) { font-size: 1em; }
						}
					}
					
			}
			section.features { background: $csfcreme; overflow: hidden; 
				h1 { margin: 0 0 1em 0; padding: 0; font-size: 2em; color: $csfblack; line-height: 1.2em; text-align: left; width: 100%; letter-spacing: 0.02em; text-transform: uppercase; }
				p { margin: 1em 0 0 0; width: auto; font-size: 0.9em; line-height: 180%; color: $csfblack; padding-bottom: 0.1em;
				@media only screen and (min-width: 768px) { font-size: 0.9em; }
				@media only screen and (min-width: 992px) { font-size: 0.9em; }
				
					&.lead { margin: 0.2em 0 0.5em 0; font-size: 1.4em; font-weight: 200; line-height: 160%; }
					a { color: #333; border-bottom: 1px solid #999;
						&:hover { color: #000; border-bottom: 1px solid #000; }
						&.sel { color: #000; border-bottom: 1px solid #000; }
						&.button { color: $csfred !important; border: 1px solid $csfred !important; padding: 0.3em 3em; font-size: 1.5em; float: left; margin: 2em 0 0 0; left: 0; letter-spacing: 0.05em; white-space: nowrap;border-radius: 2px;
							&:hover { color: #000 !important; border: 1px solid #000 !important; }
						}
					}
				}
				ul { color: $csfblack; margin: 0 0 1em 0.6em; padding-left: 0; text-indent: -0.75em;
					@media only screen and (min-width: 768px) { text-indent: -0.85em; }
					@media only screen and (min-width: 992px) { text-indent: -1em; }
						li { line-height: 140%; padding: 0.1em 0; font-size: 0.9em; list-style: none;
							&:before { content: "\003E \0020"; }
						@media only screen and (min-width: 768px) { font-size: 1em; }
						@media only screen and (min-width: 992px) { font-size: 1em; }
						}
					}
				.specialprice { float: right; margin: 25px 0 0 0; font-size: 1.2em; text-transform: uppercase; letter-spacing: 0.05em;
					span { font-size: 3em; }
				}
			}
		}
	
	&.specials { padding: 0 0 0 0; margin-top: 0; 
	@media only screen and (min-width: 768px) { }
		article { position: static; margin: 0 0 2px 0; 
			.articleimages {
				img {  }
			}
			.articletext {
				h1 { margin: 0 0 0 0; padding: 0; font-size: 3em; color: $csfblack; line-height: 0.8em; text-align: left; width: 100%; letter-spacing: 0.02em;
				@media only screen and (min-width: 768px) { text-align: center; margin: 5px 0 30px 0; font-size: 4em; line-height: 0.5em; }
				@media only screen and (min-width: 992px) { font-size: 5em; }
					
					small { margin: 0; font-size: 0.5em; color: $csfblack; letter-spacing: 0.02em;
					@media only screen and (min-width: 768px) { font-size: 0.4em; }
					@media only screen and (min-width: 992px) { font-size: 0.3em; }
					}
				}
				h2 { margin: 0.7em 0 0 0; text-transform: none; }
				p { margin: 0.5em 0 0 0;
					
				}
			}
		}
	}
	&.jobs, &.media { width: 100%; padding: 0 0 0 0; margin-top: 0; 
	@media only screen and (min-width: 768px) { padding: 0; }
	
		article { border-top: 1px solid #ddd; padding: 20px 15px; background: none;
		@media only screen and (min-width: 768px) { padding: 20px 0; }
			.articleimages {
				img { padding-bottom: 20px; width: 150px; height: auto;
				@media only screen and (min-width: 768px) { width: 100%; height: auto; }
				}
			}
			.articletext {
				h1, h2 { text-align: left; color: $csfblack; font-size: 2em; border: 0; margin: 0; padding: 0; }
				h2 { font-size: 1em; margin: 0.5em 0; line-height: 1.4em; }
				p { font-size: 1em; margin: 0.5em 0;
					a { color: $csfred; border-bottom: 1px solid #666;
					&:hover { color: #000; border-bottom: 1px solid #000; }
					}
				}
			}
		}
	}	
	
	&.mediagallery { width: 100%; padding: 15px 15px;
	@media only screen and (min-width: 768px) { padding: 15px 0; }
		
		h1 { text-transform: none;  font-size: 1.2em; border: 0; width: 100%; padding: 2em 0 1em 0;
		@media only screen and (min-width: 768px) { padding: 2em 25% 1em 25%; }
		}
		.row-fluid div { margin: 10px 0;
			img { }
		}
		
		.carousel { height: auto; background: #fff; padding: 1em;
		@media only screen and (min-width: 1300px) { width: 1300px; margin: 0 auto; }
			.carousel-indicators { padding: 0 0 0 0; bottom: 0;
				li { border-color: #333; margin: 0 4px;
					&.active { background: #333; width: 10px; height: 10px; border: 0; margin: 0 4px; }
				}
			}
		}

		.articleimages {
			img { width: 100%; height: auto; }
		}
		.articletext {
			h1, h2 { text-transform: none;  font-size: 1em; border: 0; }
			h2 { font-size: 0.8em; margin: 0.5em 0; }
			p { font-size: 0.8em; margin: 0.5em 0;
				a { color: #333; border-bottom: 1px solid #666;
				&:hover { color: #000; border-bottom: 1px solid #000; }
				}
			}
		}
	}
	}
	
	
	iframe {  }
	
	#home { display: none; width: auto; height: auto; z-index: 0; position: relative; top: 0; left: 0;
	@media only screen and (min-width: 992px) { width: 93%; height: 100%; z-index: 200000; position: absolute; top: 0; left: 60px; }
	}
}



/*______ Footer ______*/

footer { width: 100%; overflow: hidden; padding: 30px 0 0 0; margin: 50px 0 0 0; background: $csfcreme; border: 0 solid #000; font-size: 1.15em; line-height: 1.25em;
	strong {
		font-family: "Avenir Next LT W01 Demi";
		}
@media only screen and (min-width: 992px) {bottom: 0;  }
}
.contentwrap { max-width: 1200px; margin: 0 auto; clear: both;
	&.textonly { max-width: 768px; overflow: hidden; }
	}
	
.footerwrap { display: block; margin: 0 auto 30px; border: 0 dotted DodgerBlue; overflow: hidden; padding: 15px 15px;
@media only screen and (min-width: 768px) { font-size: 1em; }

	.wrap {
	@media only screen and (min-width: 992px) { display: table; }
	
	&#nlsubscription { border: 0 dotted DodgerBlue; margin: 1em 0;
	@media only screen and (min-width: 992px) { margin: 0 0 0 0; }
	@media print { display: none; }
		
		.lead {
			p { font-family: $demi; background: none; text-transform: none; font-size: 1.4em; margin: 0.5em 0 0 0; line-height: 1.3em; }
			img.nlimage { width: 147px; height: auto;
			@media only screen and (min-width: 768px) { position: static; float: right; clear: both; }
			}
		}
		form { margin: 15px 0 0 0;
			h1 { margin: 0; font-size: 1.3333em; color: #b2b2b2; line-height: 1.3em; text-transform: uppercase; }
			.form-control { background-color: $csfwhite; color: $csfblack; border-radius: 0; border: 0; height: 45px; }
			.form-group { padding: 0; margin-bottom: 5px;
			@media only screen and (min-width: 768px) { padding: 0 10px 0 0; }
			}
			.indicates-required { margin: 10px 0 0 0; }
			.btn { background: url("/assets/static/images/box-csf-red-small.png"); background-size: cover; color: $csfwhite; text-transform: uppercase; font-family: $bold; padding: 5px 20px; font-size: 1.2em; border: 0; white-space: normal; float: right; margin: 10px 0 0 0;
			&:hover { color: $csfcreme; }
			}
		}
	
		a, button { color: #ddd;
		&:hover { color: #fff; }
			img { height: 35px; width: auto;
			&.mag { height: 31px; }
			}
		}
	}
		
	&#credits { font-size: 1em; line-height: 1.5em; color: $csfblack;
		
		div { padding-top: 20px;
		@media only screen and (min-width: 992px) { padding-top: 94px; }
		&#address {
		@media only screen and (min-width: 992px) { padding-top: 20px; }
			img { width: 43px; height: auto; }
		}	
		}
		
		address { text-align: left; border: 0 dotted DodgerBlue; font-size: 1em; margin: 1em 0; padding: 0;
		@media only screen and (min-width: 768px) { font-size: 1em; margin: 1em 0 0 0; }
		/* @media only screen and (min-width: 992px) { display: inline-block; vertical-align: bottom; float: none; } */
			p { margin: 1em 0;
				@media only screen and (min-width: 992px) { margin: 0; }
			}
			p.phone { letter-spacing: 0.1em;
				em { font-size: 3.3333em; margin: 0; line-height: 1em;
					a { white-space: nowrap; margin: 0; border: 0; color: #b2b2b2; }
				}
			}
			a { text-transform: none; color: $csfblack; margin: 0 0.2em; letter-spacing: 0.1em; font-size: 0.9em; border-bottom: 1px solid $csfgrey;
			&:hover { color: $csfgrey; border-bottom: 1px solid $csfgrey; }
			}
		}
		strong { font-family: $demi; }
	
	}
	}
	
&.sitemap { max-width: 100%; background-color: #d8d4bc; margin: 0 auto;
	.sitemapwrap { max-width: 1400px; margin: 0 auto; 
		nav#sitemap { text-align: center; margin: 40px 0;
			ul { float: none; display: inline-block; text-align: left; width: 100%;
				li {
					a { color: $csfblack;
						&:hover { color: black; }
					}
				&.nav-item--level-1 { float: left; padding: 0 1em 1em 1em; width: 100%;
				@media only screen and (min-width: 768px) { width: 50%; }
				@media only screen and (min-width: 992px) { width: 33.3333%; }
					a { font-family: $bold; margin: 0 0 0.5em 0; float: left; width: 100%; }
				}
					ul {
						li { line-height: 1.5em; padding: 0;
						&.nav-item--level-2 {
							a { font-family: $sans-serif; margin: 0; }
						}
						}
					}
				}
			}
		}
	}
}
}



/*______ Tables ______*/


main#content article table { width: 100% !important; margin: 1.5em 0 3em 0; font-size: 0.8em; line-height: 150%;
@media only screen and (min-width: 480px) { font-size: 1em; }
@media only screen and (min-width: 768px) { font-size: 0.8em; }
@media only screen and (min-width: 992px) { font-size: 1em; }
	th { font-weight: bold; }
	td { padding: 0 1em 0 0;
	@media only screen and (min-width: 480px) { padding: 0 2em 0 0; }
		img { float: left; width: 90px; height: auto; background: #eee; }
		h1 { font-size: 1em !important; margin: 0 0 5px 0; }
		h2 { font-size: 0.9em !important; margin: 20px 0 5px 0; }
		h3 { font-size: 0.8em !important; color: #777; margin: 20px 0 5px 0; }
		p { font-size: 0.9em; margin: 5px 0 0 0; }
		p.date { font-size: 0.9em; margin: 0 0 5px 0; }
		small { font-size: 0.85em; margin: 0; }
		ul { font-size: 0.9em; margin: 0 0 0 1em; padding-left: 2px; text-indent: -0.9em;}
		li { line-height: 130%; padding: 0 0 0.5em 0; }
	}	
}
table.tablesorter { float: left; width: 974px; margin: 0 0 0 0;
	th { border-bottom: 1px solid #ddd; }
	td { padding: 10px 30px 10px 0; border-bottom: 1px solid #ddd;
		img { float: left; width: 90px; height: auto; background: #eee; }
		h1 { font-size: 1em !important; margin: 0 0 5px 0; }
		h2 { font-size: 0.9em !important; margin: 20px 0 5px 0; }
		h3 { font-size: 0.8em !important; color: #777; margin: 20px 0 5px 0; }
		p { font-size: 1em; margin: 5px 0 0 0; }
		p.date { font-size: 0.9em; margin: 0 0 5px 0; }
		small { font-size: 0.85em; margin: 0; }
		ul { font-size: 0.9em; margin: 0 0 0 1em; padding-left: 2px; text-indent: -0.9em;}
		li { line-height: 130%; padding: 0 0 0.5em 0; }
	}	
}



/*______ Forms ______*/


div#loginpress { width: 300px; float: left; }
form#register_member_form { width: 300px; float: right; margin: 20px 10px 0 0; }

 form { margin: 20px 0 20px 0;
	label { margin: 1.3em 0 0 0; }
 }
 
 ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #999 !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #999 !important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #999 !important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #999 !important;
}
:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color:    #999 !important;
}

	/* textarea { width: 300px; height: 200px; }
	input { width: 300px; height: 20px; margin: 0 0 0 0; padding: 0; color: #000 !important; text-transform: uppercase; }
	::-webkit-input-placeholder { color:#000 !important; text-transform: uppercase !important; }
	::-moz-placeholder { color:#000 !important; text-transform: uppercase !important; }
	:-ms-input-placeholder { color:#000 !important; text-transform: uppercase !important; }
	input:-moz-placeholder { color:#000 !important; text-transform: uppercase !important; }
	
	input[type=submit] { width: auto; height: auto; 
  display: inline-block;
  *display: inline;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  *margin-left: .3em;
  font-size: 13px;
  line-height: 18px;
  *line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  *background-color: #e6e6e6;
  background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  *border: 0;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  &:hover, &:active, &.active, &.disabled,&[disabled] {
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
  }
  }
  div.line { float: left; width: 100%; }
  ul { display: block; }
  ul li { list-style: none; display: inline; float: left; width: 100px;
	  label { margin: 5px 0 0 0; }
  }
  ul li:before { content: none !important; }
}
form input[type=checkbox], form input[type=radio] { width: auto; margin: 0 20px 0 0; }

dl { clear:both; }
dt { float:left; width: 104px; }
dd { float:left; width:auto; padding: 0 0 20px 0; }

fieldset { border: 0; margin: 0; padding: 0; }

form p { line-height: 1em !important; }*/