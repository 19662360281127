@charset "UTF-8";
/*	-------------------------------------------------
	Cuisine sans frontières
	HTML / CSS 
	Studio Roth&Maerchy 2017
	http://www.rothmaerchy.com
	-------------------------------------------------  */
/*______ Colors ______*/
/*______ Fonts ______*/
/*______ Basics ______*/
html {
  overflow-y: auto;
  text-size-adjust: auto !important;
  -moz-text-size-adjust: auto !important;
  -webkit-text-size-adjust: auto !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

::-moz-selection {
  background: #f9e0d5;
  color: #000;
  text-shadow: none; }

::selection {
  background: #f9e0d5;
  color: #000;
  text-shadow: none; }

a {
  outline: 0 !important; }

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility;
  font-weight: normal;
  letter-spacing: 1px; }

ul, ol {
  margin: 0;
  padding: 0 0 0 1.6em; }

li {
  margin: 0;
  padding: 0; }

ol {
  list-style-type: decimal; }

section.content article ul li {
  list-style: none; }

section.content article ul li:before {
  content: "\2013 \0020" !important; }

nav ul, nav li {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

textarea {
  overflow: auto;
  white-space: normal; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

.footnotes li a:rev {
  display: none; }

.none {
  display: none; }

.clearfix:after {
  content: " ";
  /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.js .nav-collapse-0.opened {
  max-height: auto !important; }

#map {
  width: 100%;
  height: 500px; }

/*------------------------------------------------------------
	Browser Reset
--------------------------------------------------------------*/
body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  white-space: normal;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

:focus {
  outline: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
  /* vertical-align: bottom; */
  /* Suppress the space beneath the baseline */
  /* Responsive images */
  /* @todo Add responsive embedded video. */
  max-width: 100%;
  height: auto;
  width: auto; }

.wf-loading h1, .wf-loading p {
  visibility: hidden !important; }

/*______ MS-Viewport Bug  ______*/
@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@-o-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 25px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: 25px; }
  [dir="rtl"] .slick-next {
    left: 25px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: -30px; }

.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "FontAwesome";
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: white;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: white;
      opacity: 0.85; }

/*______ Typography ______*/
h1 a {
  color: #282828;
  text-decoration: none; }

h1 a:hover {
  color: #000;
  border: 0; }

article strong {
  font-family: "Avenir Next LT W01 Demi";
  font-weight: normal;
  letter-spacing: 0.05em; }

small, p small, .small p {
  font-size: 0.9em;
  color: #999; }

ul {
  list-style-position: inside; }

ol {
  list-style-type: decimal;
  list-style-position: inside; }

/*______ Links and Navigation ______*/
a {
  color: #d8292d;
  text-decoration: none !important; }
  a:hover {
    color: #000;
    text-decoration: none; }
  a:active {
    color: #d8292d !important; }

a:visited {
  border: 0;
  text-decoration: none !important; }

a.anchor {
  display: block;
  height: 227px;
  margin-top: -227px;
  visibility: hidden; }

a.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none; }

a.btn {
  background-color: #d8292d;
  background: url("/assets/static/images/box-csf-red-small.png");
  background-size: cover;
  font-family: "AvenirNextLTW01-Heavy";
  text-transform: uppercase !important;
  font-size: 1.2em; }
  a.btn:hover {
    color: #ebeadc; }

a.img, a.img:hover {
  border: 0 !important; }

button {
  text-transform: uppercase; }

@media print {
  a[href]:after {
    content: none !important; } }

/*______ Basics, Body & Menu ______*/
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0 0 0 0;
  padding: 0;
  font: 16px/160%;
  background: #f6f5f4;
  background-attachment: fixed;
  font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }
  @media only screen and (min-width: 768px) {
    body {
      font-size: 14px; } }
  @media only screen and (min-width: 768px) {
    body div#frame {
      min-height: 100%;
      position: relative; } }
  body .datepicker {
    font-weight: bold; }
    body .datepicker.dropdown-menu {
      background-color: #d8292d; }
    body .datepicker td, body .datepicker th {
      color: #fff; }
    body .datepicker table tr th:hover {
      background: red !important;
      cursor: pointer; }
    body .datepicker table tr td.day:hover, body .datepicker table tr td.day.focused {
      background: red;
      cursor: pointer; }
    body .datepicker table tr td.old, body .datepicker table tr td.new {
      color: red; }
    body .datepicker table tr td.disabled, body .datepicker table tr td.disabled:hover {
      background: none;
      color: red;
      cursor: default; }
  body .datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #d8292d;
    border-top: 0;
    position: absolute; }
  body .dropdown-menu {
    z-index: 10000; }
  body .datepicker table tr td.active.active {
    background: #ebeadc;
    color: #282828; }
    body .datepicker table tr td.active.active:hover {
      background: #a80031; }
  body .pswp {
    z-index: 10000; }
  body #buorg {
    z-index: 1111111 !important;
    position: absolute !important; }
  body .buorg {
    z-index: 1111111 !important;
    position: absolute !important;
    width: 100%;
    padding: 1em 4em;
    top: 0px;
    left: 0px;
    border-bottom: 1px solid #A29330;
    background: #FDF2AB no-repeat 13px center url(//browser-update.org/img/small/c.gif);
    text-align: left;
    cursor: pointer;
    font-family: Arial,Helvetica,sans-serif !important;
    color: #000;
    font-size: 12px; }
    body .buorg #buorgclose {
      float: right; }
    body .buorg a {
      border-bottom: 1px solid #666; }
  body .fancybox-overlay {
    z-index: 18000; }
  body .fancybox-wrap {
    z-index: 18010; }

.carousel-control.left, .carousel-control.right {
  background: none; }

.carousel li .active {
  margin: 0 0 -1px 0 !important; }

.carousel .carousel-indicators {
  display: none; }
  @media only screen and (min-width: 768px) {
    .carousel .carousel-indicators {
      display: inline-table; } }
  @media only screen and (min-width: 768px) {
    .carousel .carousel-indicators li {
      display: inline-block; } }

.carousel-inner > .item {
  -webkit-transition: 1.5s ease-in-out left;
  -moz-transition: 1.5s ease-in-out left;
  -o-transition: 1.5s ease-in-out left;
  transition: 1.5s ease-in-out left; }
  @media all and (transform-3d), (-webkit-transform-3d), (-moz-transform-3d) {
    .carousel-inner > .item {
      -webkit-transition: -webkit-transform 1.5s ease-in-out 0s;
      -moz-transition: -moz-transform 1.5s ease-in-out 0s;
      -o-transition: -o-transform 1.5s ease-in-out 0s;
      transition: transform 1.5s ease-in-out 0s; } }

.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0; }

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0); }

.carousel-fade .carousel-control {
  z-index: 2; }

html,
body,
.carousel,
.carousel-inner,
.carousel-inner .item {
  height: 100%; }

.tos-desktop .tos-wrapper.tos-fixed {
  background: #000 !important; }

.tos-close {
  top: 60px;
  left: auto; }

.tos-close span:before, .tos-close span:after {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: 0;
  margin-right: 0; }

.tos-wrapper .tos-caption {
  font-size: 1em; }
  @media only screen and (min-width: 768px) {
    .tos-wrapper .tos-caption {
      font-size: 1.2em;
      line-height: 25px; } }
  @media only screen and (min-width: 992px) {
    .tos-wrapper .tos-caption {
      font-size: 1.2em;
      line-height: 25px; } }

.dds-widget-container {
  margin: 0 15px; }

#lema-container.lema-container {
  margin: 0 auto !important; }
  #lema-container.lema-container .lema-step-header-text {
    padding-left: 52px !important; }
  #lema-container.lema-container .lema-step-number {
    left: 12px !important; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  margin: 2em 0; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

.jscroll-inner {
  overflow: hidden; }

#infscr-loading {
  text-align: center;
  margin: 20px 0; }
  #infscr-loading img {
    margin: 20px 0; }
  #infscr-loading div {
    margin: 0 auto;
    font-size: 2em; }

#cuisine-sf-default-stored_customer_donation_receipt {
  display: none !important; }

#cuisine-sf-default-stored_customer_donation_receipt + label {
  display: none !important; }

/*______ Content ______*/
header#site {
  position: fixed;
  top: -10px;
  left: 0;
  margin: 0;
  width: 100%;
  z-index: 8000;
  background-color: #282828;
  padding: 0.5em 0 0 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column; }
  @media only screen and (min-width: 480px) {
    header#site {
      left: 0;
      top: -5px;
      margin: 0 0 0 0; } }
  @media only screen and (min-width: 768px) {
    header#site {
      flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    header#site {
      overflow: visible;
      position: fixed;
      top: -5px;
      left: 0;
      padding: 0 0 0 0;
      -webkit-box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 100px rgba(0, 0, 0, 0.3); } }
  @media only screen and (min-width: 1025px) {
    header#site {
      margin: auto; } }
  header#site.smaller #logo img.desktopno {
    display: none; }
  header#site.smaller #logo img.desktopyes {
    margin-top: -200px; }
  @media only screen and (min-width: 992px) {
    header#site.smaller #logo {
      height: 146px;
      top: 5px; }
      header#site.smaller #logo img.desktopno {
        display: none; }
      header#site.smaller #logo img.desktopyes {
        margin-top: 0;
        display: block; } }
  header#site #logo {
    float: none;
    text-align: left;
    border: 0 dotted DodgerBlue;
    width: 120px; }
    @media only screen and (min-width: 992px) {
      header#site #logo {
        width: 290px;
        height: auto;
        margin: 0;
        text-align: left;
        transition: all 0.3s; } }
    header#site #logo h1 {
      font-size: 2em;
      font-weight: normal;
      font-style: normal;
      text-transform: uppercase; }
      @media only screen and (min-width: 480px) {
        header#site #logo h1 {
          font-size: 0; } }
      header#site #logo h1 a {
        color: #000;
        margin: 0;
        padding: 0;
        border: 0; }
        header#site #logo h1 a img {
          width: 100%;
          height: auto;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s; }
          @media only screen and (min-width: 768px) {
            header#site #logo h1 a img {
              width: 100%;
              height: auto; } }
    header#site #logo .mobilelogo {
      position: absolute;
      top: 10px;
      left: 42px;
      z-index: -10;
      width: 43px;
      height: auto; }
      @media only screen and (min-width: 992px) {
        header#site #logo .mobilelogo {
          display: none; } }
      header#site #logo .mobilelogo img {
        width: 43px !important;
        height: auto; }
  header#site a.navtrigger {
    position: absolute;
    top: 28px;
    right: 20px;
    float: right;
    width: auto;
    padding: 0;
    font-size: 1em;
    background: none;
    color: #ccc !important;
    z-index: 100000;
    display: block; }
    @media only screen and (min-width: 992px) {
      header#site a.navtrigger {
        display: none !important; } }
    header#site a.navtrigger:hover {
      color: #fff; }
    header#site a.navtrigger img {
      width: 32px;
      height: 32px; }
  header#site a.mobiledonate {
    position: absolute;
    top: 32px;
    right: 75px;
    padding: 2px 10px;
    background: url("/assets/static/images/box-csf-red-small.png");
    background-size: cover;
    font-family: "AvenirNextLTW01-Heavy";
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2em; }
    header#site a.mobiledonate:hover {
      color: #ebeadc; }
    header#site a.mobiledonate:active {
      color: #282828 !important; }
    @media only screen and (min-width: 992px) {
      header#site a.mobiledonate {
        display: none; } }
  header#site ul#meta {
    font-weight: normal;
    font-style: normal;
    font-family: "Avenir Next LT W01 Demi";
    border: 0 dotted DodgerBlue;
    text-align: left;
    float: left;
    padding: 0 0 1em 0;
    width: 254px;
    font-size: 1em; }
    @media only screen and (min-width: 992px) {
      header#site ul#meta {
        float: right;
        width: auto;
        padding: 15px 20px 0 0;
        text-align: right;
        top: 0; } }
    header#site ul#meta li {
      float: left;
      display: inline;
      padding: 0 0.3em;
      position: relative; }
      @media only screen and (min-width: 992px) {
        header#site ul#meta li {
          width: auto;
          float: left; } }
      header#site ul#meta li a {
        float: left;
        width: auto;
        margin: 0;
        padding: 0.2em 0 0.15em 0;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.05em; }
        @media only screen and (min-width: 992px) {
          header#site ul#meta li a.img {
            padding: 0.05em 0 0.15em 0;
            margin: 0 0.3em;
            color: #fff; } }
        header#site ul#meta li a:hover {
          color: #d8292d; }
        header#site ul#meta li a:active {
          color: #d8292d; }
        header#site ul#meta li a.sel {
          color: #d8292d; }
          @media only screen and (min-width: 992px) {
            header#site ul#meta li a.sel {
              color: #d8292d; } }
        header#site ul#meta li a .fa {
          font-size: 1.2em; }
      header#site ul#meta li.langdesktop {
        display: none;
        font-size: 1em; }
        @media only screen and (min-width: 992px) {
          header#site ul#meta li.langdesktop {
            display: block; } }
  header#site #main {
    position: fixed;
    top: 0;
    right: -100%;
    text-align: left;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    float: left;
    letter-spacing: 0.05em;
    font-size: 1.6666em;
    font-weight: normal;
    font-style: normal;
    border: 0 dotted DodgerBlue;
    z-index: 201000;
    overflow: hidden;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    /* enables momentum scrolling in iOS overflow elements */
    font-family: "AvenirNextLTW01-HeavyCn_721323"; }
    @media only screen and (min-width: 992px) {
      header#site #main {
        position: static;
        top: auto;
        right: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        font-size: 1.1em;
        padding: 25px 0 0 180px;
        font-family: "AvenirNextLTW01-Heavy";
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; } }
    @media only screen and (min-width: 1200px) {
      header#site #main {
        font-size: 1.4em; } }
    @media only screen and (min-width: 1400px) {
      header#site #main {
        font-size: 1.6666em; } }
    @media print {
      header#site #main {
        display: none; } }
    header#site #main a {
      text-decoration: none; }
    header#site #main .langmobile {
      float: left;
      padding: 30px 15px 2em 15px;
      height: auto;
      display: block;
      font-family: "AvenirNextLTW01-DemiCn";
      width: 100%;
      font-size: 0.7em; }
      @media only screen and (min-width: 992px) {
        header#site #main .langmobile {
          display: none; } }
      header#site #main .langmobile li {
        float: left;
        display: inline-block;
        padding: 0;
        margin: 0 0.5em 0 0; }
        header#site #main .langmobile li a {
          float: none;
          display: inline-block;
          width: auto;
          margin: 0;
          padding: 0.2em 0 0 0;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 0.05em; }
          header#site #main .langmobile li a:hover {
            color: #d8292d; }
          header#site #main .langmobile li a:active {
            color: #d8292d; }
          header#site #main .langmobile li a.sel {
            color: #d8292d; }
    header#site #main #mainmenu, header#site #main #sidemenu {
      float: left;
      margin: 0 0 0 0;
      padding: 0 20px 0 0;
      height: auto;
      display: block;
      font-weight: bold;
      width: 100%; }
      @media only screen and (min-width: 992px) {
        header#site #main #mainmenu, header#site #main #sidemenu {
          margin: 0;
          padding: 0 20px 0 0;
          width: auto; } }
      header#site #main #mainmenu li, header#site #main #sidemenu li {
        float: left;
        display: inline-block;
        padding: 0;
        width: 100%;
        position: relative; }
        @media only screen and (min-width: 992px) {
          header#site #main #mainmenu li, header#site #main #sidemenu li {
            width: auto;
            text-align: left; } }
        header#site #main #mainmenu li a, header#site #main #sidemenu li a {
          float: none;
          display: inline-block;
          width: auto;
          margin: 0;
          padding: 0.2em 15px 0 15px;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          line-height: 1.2em; }
          @media only screen and (min-width: 992px) {
            header#site #main #mainmenu li a, header#site #main #sidemenu li a {
              color: #fff;
              margin: 0 0.4em;
              padding: 2px 0 25px 0; } }
          @media only screen and (min-width: 1200px) {
            header#site #main #mainmenu li a, header#site #main #sidemenu li a {
              padding: 2px 0 19px 0; } }
          @media only screen and (min-width: 1400px) {
            header#site #main #mainmenu li a, header#site #main #sidemenu li a {
              padding: 2px 0 16px 0; } }
          header#site #main #mainmenu li a:hover, header#site #main #sidemenu li a:hover {
            color: #d8292d; }
          header#site #main #mainmenu li a:active, header#site #main #sidemenu li a:active {
            color: #d8292d; }
          header#site #main #mainmenu li a.sel, header#site #main #sidemenu li a.sel {
            color: #d8292d; }
        header#site #main #mainmenu li ul, header#site #main #sidemenu li ul {
          display: none;
          width: 100%;
          float: left; }
          @media only screen and (min-width: 992px) {
            header#site #main #mainmenu li ul, header#site #main #sidemenu li ul {
              float: none;
              width: 240px;
              position: absolute;
              display: none;
              top: 45px;
              left: 0px;
              width: auto;
              z-index: 10000; } }
          @media only screen and (min-width: 1200px) {
            header#site #main #mainmenu li ul, header#site #main #sidemenu li ul {
              top: 45px; } }
          header#site #main #mainmenu li ul li, header#site #main #sidemenu li ul li {
            display: inline;
            float: none;
            height: auto; }
            header#site #main #mainmenu li ul li a, header#site #main #sidemenu li ul li a {
              width: 100%;
              color: #fff;
              padding: 0.15em 15px 0.15em 15px; }
              @media only screen and (min-width: 992px) {
                header#site #main #mainmenu li ul li a, header#site #main #sidemenu li ul li a {
                  width: 270px;
                  height: auto;
                  border-bottom: 1px solid #ddd;
                  padding: 0.3em 0.6em 0.3em 0.6em;
                  font-size: 0.8em;
                  background-color: #fff;
                  color: #b2b2b2;
                  font-family: "Avenir Next LT W01 Demi";
                  text-transform: none; } }
              header#site #main #mainmenu li ul li a:hover, header#site #main #sidemenu li ul li a:hover {
                background-color: #333;
                color: #d8292d; }
        header#site #main #mainmenu li:hover, header#site #main #sidemenu li:hover {
          color: #d8292d; }
          header#site #main #mainmenu li:hover a, header#site #main #sidemenu li:hover a {
            color: #d8292d; }
          header#site #main #mainmenu li:hover ul li a, header#site #main #sidemenu li:hover ul li a {
            background-color: #fff;
            color: #b2b2b2; }
            @media only screen and (min-width: 992px) {
              header#site #main #mainmenu li:hover ul li a, header#site #main #sidemenu li:hover ul li a {
                color: #282828; } }
            header#site #main #mainmenu li:hover ul li a:hover, header#site #main #sidemenu li:hover ul li a:hover {
              background-color: none;
              color: #d8292d; }
              @media only screen and (min-width: 992px) {
                header#site #main #mainmenu li:hover ul li a:hover, header#site #main #sidemenu li:hover ul li a:hover {
                  background-color: #d8292d;
                  color: #fff; } }
          header#site #main #mainmenu li:hover ul li.nav-item--is-current a, header#site #main #sidemenu li:hover ul li.nav-item--is-current a {
            background-color: #d8292d;
            color: #fff; }
          header#site #main #mainmenu li:hover ul, header#site #main #sidemenu li:hover ul {
            display: block; }
        header#site #main #mainmenu li.nav-item--is-top-parent a, header#site #main #sidemenu li.nav-item--is-top-parent a {
          color: #d8292d; }
          @media only screen and (min-width: 992px) {
            header#site #main #mainmenu li.nav-item--is-top-parent a, header#site #main #sidemenu li.nav-item--is-top-parent a {
              color: #d8292d; } }
        header#site #main #mainmenu li.parent-selected a, header#site #main #sidemenu li.parent-selected a {
          color: #333;
          border-bottom: 1px solid #282828; }
    header#site #main #sidemenu {
      float: left; }
      @media only screen and (min-width: 992px) {
        header#site #main #sidemenu {
          float: right;
          margin: 0;
          padding: 0;
          width: auto; } }
      @media only screen and (min-width: 992px) {
        header#site #main #sidemenu li a {
          margin: 0 0.2em;
          padding: 2px 10px;
          background: url("/assets/static/images/box-csf-red-small.png");
          background-size: cover; } }
      header#site #main #sidemenu li a:hover {
        color: #d8292d; }
        @media only screen and (min-width: 992px) {
          header#site #main #sidemenu li a:hover {
            color: #ebeadc; } }
      header#site #main #sidemenu li a:active {
        color: #d8292d; }
        @media only screen and (min-width: 992px) {
          header#site #main #sidemenu li a:active {
            color: #ebeadc; } }
      header#site #main #sidemenu li a.sel {
        color: #d8292d; }
        @media only screen and (min-width: 992px) {
          header#site #main #sidemenu li a.sel {
            color: #ebeadc; } }
      header#site #main #sidemenu li ul {
        position: absolute;
        display: none;
        top: 40px;
        right: 0px;
        width: auto;
        z-index: 10000; }
        @media only screen and (min-width: 992px) {
          header#site #main #sidemenu li ul {
            width: 240px; } }
        header#site #main #sidemenu li ul li {
          display: inline;
          float: none;
          height: auto; }
          header#site #main #sidemenu li ul li a:hover {
            background-color: #333;
            color: #d8292d; }
      header#site #main #sidemenu li.nav-item--is-top-parent a {
        color: #fff; }
        @media only screen and (min-width: 992px) {
          header#site #main #sidemenu li.nav-item--is-top-parent a {
            color: #fff; } }
        header#site #main #sidemenu li.nav-item--is-top-parent a:hover {
          color: #ebeadc; }
      @media only screen and (min-width: 992px) {
        header#site #main #sidemenu li#kb-link a {
          background: url(../images/box-kb-beige.png);
          background-size: cover;
          color: #333; } }
    header#site #main ul#mobilemeta {
      font-weight: normal;
      font-style: normal;
      font-family: "AvenirNextLTW01-DemiCn";
      border: 0 dotted DodgerBlue;
      text-align: left;
      float: left;
      margin: 2em auto 0 auto;
      padding: 0 0 1em 0;
      width: 254px;
      font-size: 0.7em;
      display: block; }
      @media only screen and (min-width: 992px) {
        header#site #main ul#mobilemeta {
          display: none;
          float: right;
          width: auto;
          padding: 20px 0 0 0;
          text-align: right;
          top: 0; } }
      header#site #main ul#mobilemeta li {
        float: left;
        display: inline;
        padding: 0;
        position: relative;
        width: 100%; }
        @media only screen and (min-width: 992px) {
          header#site #main ul#mobilemeta li {
            width: auto;
            float: left;
            padding: 0 0.3em; } }
        header#site #main ul#mobilemeta li a {
          float: left;
          width: auto;
          margin: 0;
          padding: 0.2em 15px 0.15em 15px;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 0.05em; }
          @media only screen and (min-width: 992px) {
            header#site #main ul#mobilemeta li a.img {
              padding: 0.05em 0 0.15em 0;
              margin: 0 0.3em;
              color: #fff; } }
          header#site #main ul#mobilemeta li a:hover {
            color: #d8292d; }
          header#site #main ul#mobilemeta li a:active {
            color: #d8292d; }
          header#site #main ul#mobilemeta li a.sel {
            color: #d8292d; }
            @media only screen and (min-width: 992px) {
              header#site #main ul#mobilemeta li a.sel {
                color: #d8292d; } }
          header#site #main ul#mobilemeta li a .fa {
            font-size: 1.2em; }
        header#site #main ul#mobilemeta li.socialmedia {
          margin: 0.5em 0 0 0; }
          header#site #main ul#mobilemeta li.socialmedia a {
            padding: 0.2em 5px 0.15em 15px; }
        header#site #main ul#mobilemeta li.langdesktop {
          display: none;
          font-size: 1em; }
          @media only screen and (min-width: 992px) {
            header#site #main ul#mobilemeta li.langdesktop {
              display: block; } }

.moodimagewrap {
  position: relative;
  width: 100%;
  overflow: visible;
  max-width: 100%;
  margin: 0 auto;
  border: 0 dotted DodgerBlue;
  min-height: 100px; }
  @media only screen and (min-width: 768px) {
    .moodimagewrap {
      min-height: 100px; } }
  .moodimagewrap.noimage {
    background: #000;
    margin-bottom: 114px;
    min-height: 41px; }
    @media only screen and (min-width: 768px) {
      .moodimagewrap.noimage {
        background: #000;
        min-height: 0;
        height: 41px; } }
    @media only screen and (min-width: 1025px) {
      .moodimagewrap.noimage {
        background: #fff; } }
  .moodimagewrap section.slick-slide {
    height: auto;
    width: 100%;
    max-height: 864px;
    padding: 0;
    margin: 0 auto 0 auto; }
    @media only screen and (min-width: 768px) {
      .moodimagewrap section.slick-slide {
        max-height: 864px; } }
    @media only screen and (min-width: 992px) {
      .moodimagewrap section.slick-slide {
        max-height: 864px;
        margin: 0 auto 0 auto; } }
    @media only screen and (min-width: 1400px) {
      .moodimagewrap section.slick-slide {
        max-height: none; } }
    .moodimagewrap section.slick-slide img {
      width: 100%;
      height: auto; }
    .moodimagewrap section.slick-slide #moodimagecarousel {
      text-align: center;
      background: #333; }
      .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption {
        /* position: absolute; width: 30%; height: 30%; top: 35%; left: 35%; */
        float: left;
        position: relative;
        width: 100%;
        height: 70px;
        top: auto;
        left: auto;
        right: auto;
        bottom: 0;
        background: #333;
        margin: 0 0 0 0;
        padding: 10px;
        /* corr {
				@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; text-align: center; position: relative; }
				}*/ }
        @media only screen and (min-width: 768px) {
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption {
            display: table;
            float: none;
            position: absolute;
            width: 25%;
            min-height: 80px;
            height: auto;
            top: 38%;
            left: 40%;
            bottom: 20px;
            padding: 1em 0.5em;
            background: rgba(0, 0, 0, 0.8);
            font-size: 0.8em; } }
        @media only screen and (min-width: 992px) {
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption {
            width: 20%;
            height: auto;
            top: 40%;
            left: 40%;
            bottom: 20px;
            padding: 20px 10px;
            background: rgba(0, 0, 0, 0.8);
            font-size: 0.9em; } }
        @media only screen and (min-width: 1200px) {
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption {
            width: 20%;
            height: auto;
            top: 42%;
            left: 40%;
            bottom: 20px;
            padding: 1em 0.5em;
            background: rgba(0, 0, 0, 0.8);
            font-size: 1em; } }
        @media only screen and (min-width: 2100px) {
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption {
            height: auto;
            top: 44%; } }
        @media only screen and (min-width: 768px) {
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption .captiontext {
            display: table-cell;
            vertical-align: middle; } }
        .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption h1, .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption p {
          color: #fff;
          text-transform: uppercase;
          text-shadow: none;
          font-size: 1.5em;
          line-height: 1.2em;
          text-align: center; }
        .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption p {
          font-size: 0.7em;
          letter-spacing: 0.1em;
          margin: 0.5em 0 0 0; }
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption p a {
            color: #ddd;
            border-bottom: 1px solid #999; }
            .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption p a:hover {
              color: #fff;
              border-bottom: 1px solid #fff; }
        .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption.seethrough {
          background: rgba(255, 255, 255, 0.8); }
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption.seethrough h1, .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption.seethrough p {
            color: #282828; }
          .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption.seethrough p a {
            color: #333;
            border-bottom: 1px solid #666; }
            .moodimagewrap section.slick-slide #moodimagecarousel .carousel-caption.seethrough p a:hover {
              color: #000;
              border-bottom: 1px solid #000; }
      .moodimagewrap section.slick-slide #moodimagecarousel a.imagelink:hover .carousel-caption {
        background: #a30b2f; }
      .moodimagewrap section.slick-slide #moodimagecarousel .carousel-indicators li {
        margin: 1px 4px; }
        .moodimagewrap section.slick-slide #moodimagecarousel .carousel-indicators li.active {
          margin: 0px 3px; }

#bookmobile {
  display: block;
  background: #d8292d;
  text-align: center; }
  #bookmobile a {
    color: #fff;
    font-size: 2em; }
  @media only screen and (min-width: 992px) {
    #bookmobile {
      display: none; } }

#container {
  width: 100%;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    #container {
      background-repeat: repeat-y;
      background-attachment: fixed;
      background-size: cover;
      background-position: center top; } }

#contentframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 67px auto 0 auto;
  border: 0 dotted DodgerBlue; }
  @media only screen and (min-width: 480px) {
    #contentframe {
      margin: 71px auto 0 auto; } }
  @media only screen and (min-width: 768px) {
    #contentframe {
      padding: 0;
      background-color: rgba(255, 255, 255, 0); } }
  @media only screen and (min-width: 992px) {
    #contentframe {
      margin: 0 0 0 0;
      padding: 104px 0 0 0;
      /* padding: 0 0 285px 0;*/ } }

.breadcrumbs {
  font-size: 1em;
  margin: 0 0 2em 0;
  padding: 0;
  text-align: center;
  list-style: none;
  line-height: 1.5em; }
  @media only screen and (min-width: 768px) {
    .breadcrumbs {
      font-size: 1.5em; } }
  .breadcrumbs li {
    display: inline;
    margin: 0; }
    .breadcrumbs li:before {
      content: "›";
      padding: 0 0.4em 0 0.4em; }
    .breadcrumbs li a {
      color: #282828; }
      .breadcrumbs li a:hover {
        color: #d8292d; }
    .breadcrumbs li.home:before {
      content: ""; }

main#content {
  float: none;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  z-index: 0;
  min-height: 0;
  overflow: hidden;
  clear: both;
  /* #homepagenews { padding-top: 100px; padding-bottom: 100px; float: left; text-align: center; background-attachment: scroll !important;
	@media only screen and (min-width: 992px) {  }
	
		#voucher { background-color: $csfblack; color: $csfwhite; margin: 0 auto; padding: 1.5em 1em 1em 1em; float: none; margin: 20px auto; overflow: hidden;
			a { color: $csfwhite !important; float: left; width: 100%; }
			span { float: left; line-height: 1.3em; text-transform: uppercase; letter-spacing: 1px; font-weight: normal; font-size: 2em; margin: 0.15em 0 0 0;
		@media only screen and (min-width: 768px) { float: none; }
			}
			img { width: 75px; height: auto; float: right;
			@media only screen and (min-width: 768px) { margin: -6px 0 0 0; }
			}
		}
		h1 { float: none; margin: 0 auto; background-color: $csfred; color: $csfwhite; line-height: 1.3em; text-transform: uppercase; letter-spacing: 1px; font-weight: normal; font-style: normal; font-size: 2em; }
		.news { margin: 0 auto; padding: 1.5em 2em 1em 2em; float: none; text-align: left; border-bottom: 1px solid #ccc;
			h1 { color: $csfblack; background-color: transparent; line-height: 1.2em; text-transform: uppercase; letter-spacing: 1px;  margin: 0; font-size: 2em;
			@media only screen and (min-width: 768px) { font-size: 2em; }
			@media only screen and (min-width: 992px) { font-size: 2em; }
			}
			p { line-height: 160%; padding: 0.6em 0; }
			a { font-size: 1em; letter-spacing: 0em; color: $csfblack; text-transform: none; border-bottom: 1px solid $csfgrey;
				&.download { font-size: 1.33em; letter-spacing: 0.05em; text-transform: uppercase; color: $csfred; border: 0; }
			}
		}
	} */
  /* ---- button ---- */
  /* ---- button-group ---- */ }
  @media only screen and (min-width: 768px) {
    main#content {
      min-height: 1000px;
      padding: 0 0 130px 0; } }
  @media only screen and (min-width: 992px) {
    main#content {
      padding: 0 0 130px 0; } }
  main#content.home {
    padding: 0;
    max-width: 100%; }
  main#content div.wrap {
    margin: 0;
    padding: 0;
    overflow: hidden; }
  main#content .block {
    float: left;
    width: 100%;
    margin: 0;
    overflow: hidden;
    border: 0 dotted DodgerBlue; }
  main#content .projectblock {
    float: left;
    width: 100%;
    margin: 0;
    overflow: hidden;
    border: 0 dotted DodgerBlue; }
  main#content #homepagefeatures {
    padding: 0; }
    main#content #homepagefeatures .item {
      position: relative; }
      main#content #homepagefeatures .item img {
        width: 100%;
        height: auto; }
      main#content #homepagefeatures .item .carousel-caption {
        float: left;
        position: relative;
        width: 100%;
        height: 125px;
        top: 0;
        left: auto;
        right: auto;
        bottom: auto;
        background: #ebeadc;
        margin: 0 0 0 0;
        padding: 10px;
        /* corr {
					@media only screen and (min-width: 768px) { display: table-cell; vertical-align: middle; text-align: center; position: relative; }
					}*/ }
        @media only screen and (min-width: 768px) {
          main#content #homepagefeatures .item .carousel-caption {
            display: table;
            float: none;
            position: absolute;
            width: 25%;
            min-height: 80px;
            height: auto;
            top: 38%;
            left: 40%;
            bottom: 20px;
            padding: 1em 0.5em;
            background: rgba(252, 249, 247, 0.8);
            font-size: 0.8em; } }
        @media only screen and (min-width: 992px) {
          main#content #homepagefeatures .item .carousel-caption {
            width: 25%;
            height: auto;
            top: 40%;
            left: 38%;
            bottom: 20px;
            padding: 20px 10px;
            font-size: 0.9em; } }
        @media only screen and (min-width: 1200px) {
          main#content #homepagefeatures .item .carousel-caption {
            width: 25%;
            height: auto;
            top: 42%;
            left: 38%;
            bottom: 20px;
            padding: 3em 0.5em;
            font-size: 1em; } }
        @media only screen and (min-width: 2100px) {
          main#content #homepagefeatures .item .carousel-caption {
            height: auto;
            top: 44%; } }
        @media only screen and (min-width: 768px) {
          main#content #homepagefeatures .item .carousel-caption .captiontext {
            display: table-cell;
            vertical-align: middle; } }
        main#content #homepagefeatures .item .carousel-caption h1, main#content #homepagefeatures .item .carousel-caption p {
          color: #282828;
          text-transform: uppercase;
          text-shadow: none;
          font-size: 3.33em;
          line-height: 1.2em;
          text-align: center; }
        main#content #homepagefeatures .item .carousel-caption p {
          font-size: 2em;
          letter-spacing: 0.03em;
          margin: 0; }
          main#content #homepagefeatures .item .carousel-caption p a {
            color: #b2b2b2;
            border-bottom: 1px solid #999; }
            main#content #homepagefeatures .item .carousel-caption p a:hover {
              color: #282828;
              border-bottom: 1px solid #fff; }
        main#content #homepagefeatures .item .carousel-caption.seethrough {
          background: rgba(255, 255, 255, 0.8); }
          main#content #homepagefeatures .item .carousel-caption.seethrough h1, main#content #homepagefeatures .item .carousel-caption.seethrough p {
            color: #282828; }
          main#content #homepagefeatures .item .carousel-caption.seethrough p a {
            color: #333;
            border-bottom: 1px solid #666; }
            main#content #homepagefeatures .item .carousel-caption.seethrough p a:hover {
              color: #000;
              border-bottom: 1px solid #000; }
  main#content #subnav {
    font-size: 2em;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.05em;
    padding: 1em 0; }
    @media only screen and (min-width: 768px) {
      main#content #subnav {
        padding: 0 6px 0 15px; } }
    main#content #subnav ul {
      float: left;
      width: 100%;
      position: relative;
      border-radius: 2px; }
      main#content #subnav ul li a {
        background-color: rgba(252, 249, 247, 0.95);
        color: #282828;
        float: left;
        width: 100%;
        padding: 0.3em 5%;
        border-bottom: 1px solid #ddd; }
        main#content #subnav ul li a span {
          font-size: 0.7em;
          padding: 0 0 0 0.5em; }
        main#content #subnav ul li a .icon {
          width: 30px;
          height: 30px;
          float: right;
          background-position: left top;
          background-repeat: no-repeat;
          background-size: 30px 60px; }
        main#content #subnav ul li a:hover {
          background-color: #ebeadc;
          color: #282828; }
          main#content #subnav ul li a:hover .icon {
            background-position: left top; }
      main#content #subnav ul li.selected a {
        background-color: #d8292d;
        color: #fff; }
        main#content #subnav ul li.selected a .icon {
          background-position: left -30px; }
      main#content #subnav ul li.overview a {
        background-color: rgba(252, 249, 247, 0.95);
        color: #d8292d; }
        main#content #subnav ul li.overview a:hover {
          background-color: #ebeadc;
          color: #282828; }
      main#content #subnav ul li.overview.selected a {
        background-color: #d8292d;
        color: #fff; }
        main#content #subnav ul li.overview.selected a:hover {
          background-color: #d8292d;
          color: #fff; }
  main#content #featuredimage img {
    width: 100%;
    height: auto; }
  main#content .nofeaturedimage {
    height: 100px; }
  main#content .articleintro header#entry {
    text-align: center;
    position: relative;
    top: -12px;
    margin: 0 0 50px 0; }
    main#content .articleintro header#entry h1 {
      font-size: 1.2em;
      background: url("/static/images/box-csf-black-big.png");
      background-size: cover;
      display: inline;
      color: #fff;
      text-transform: uppercase;
      font-family: "Avenir Next LT W01 Bold";
      padding: 5px 10px; }
      @media only screen and (min-width: 481px) {
        main#content .articleintro header#entry h1 {
          font-size: 2em; } }
      @media only screen and (min-width: 768px) {
        main#content .articleintro header#entry h1 {
          font-size: 3em; } }
      @media only screen and (min-width: 992px) {
        main#content .articleintro header#entry h1 {
          font-size: 4em; } }
  main#content .contentwrap {
    max-width: 1200px;
    margin: 0 auto;
    clear: both; }
    main#content .contentwrap.textonly {
      max-width: 768px;
      overflow: hidden; }
  main#content article {
    margin: 0;
    overflow: visible;
    border: 0 dotted DodgerBlue;
    padding: 10px;
    border-radius: 2px;
    position: relative; }
    @media only screen and (min-width: 768px) {
      main#content article {
        margin: 0 0 0 0;
        /* margin-left: 25%; */ } }
    @media only screen and (min-width: 992px) {
      main#content article {
        padding: 0; } }
    main#content article.homeintro .articletext {
      font-family: "Avenir Next LT W01 Demi"; }
      main#content article.homeintro .articletext p {
        font-size: 1.6em;
        line-height: 1.3em;
        margin: 1em 0 0.5em 0; }
      @media only screen and (min-width: 992px) {
        main#content article.homeintro .articletext {
          font-size: 1.2em;
          padding: 70px 80px; } }
    main#content article.homeintro #testimonialwrap #testimonialcarousel {
      margin: 20px 0 0 0;
      min-height: 580px; }
      main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-control .glyphicon-chevron-left, main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-control .glyphicon-chevron-right, main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-control .icon-next, main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-control .icon-prev {
        top: 100px; }
      main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item {
        min-height: 580px; }
        @media only screen and (min-width: 768px) {
          main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item {
            min-height: 550px; } }
        main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item img {
          border-radius: 50%;
          max-width: 220px;
          margin: 0 auto; }
        main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption {
          position: static;
          color: #282828;
          text-align: center;
          padding: 1em 0;
          text-shadow: 0 0 0 rgba(0, 0, 0, 0); }
          @media only screen and (min-width: 481px) {
            main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption {
              padding: 1em 2em; } }
          @media only screen and (min-width: 768px) {
            main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption {
              padding: 1em 6em; } }
          @media only screen and (min-width: 992px) {
            main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption {
              padding: 1em 2em;
              text-align: left; } }
          main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption p {
            font-size: 1.2em;
            text-shadow: 0 0 0 rgba(0, 0, 0, 0);
            font-family: "Avenir Next LT W01 Demi";
            line-height: 1.4em; }
            @media only screen and (min-width: 768px) {
              main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption p {
                font-size: 1.4em; } }
            main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption p.info {
              font-size: 1em;
              color: #282828;
              margin: 1em 0 0 0;
              font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif;
              line-height: 1.4em; }
          main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div {
            margin: 0.5em 0 1em 0;
            text-align: left; }
            main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info {
              line-height: 1.3em; }
              main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul {
                color: #282828;
                margin: 0 0 1em 0.6em;
                padding-left: 0;
                text-indent: -0.75em;
                text-align: center; }
                @media only screen and (min-width: 768px) {
                  main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul {
                    text-indent: -0.85em; } }
                @media only screen and (min-width: 992px) {
                  main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul {
                    text-indent: -0.6em;
                    text-align: left; } }
                main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul li {
                  line-height: 140%;
                  padding: 0.1em 0;
                  font-size: 0.9em;
                  list-style: none; }
                  main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul li:before {
                    content: "\003E \0020"; }
                  @media only screen and (min-width: 768px) {
                    main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul li {
                      font-size: 1em; } }
                  @media only screen and (min-width: 992px) {
                    main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption div.info ul li {
                      font-size: 1em; } }
          main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption .support {
            text-align: center;
            margin: 40px 0 0 0; }
            main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption .support .btn {
              background: url("/assets/static/images/box-csf-red-small.png");
              background-size: cover;
              color: #fff;
              text-transform: uppercase;
              font-family: "Avenir Next LT W01 Bold";
              padding: 5px 20px;
              font-size: 0.8em;
              border: 0;
              white-space: normal; }
              main#content article.homeintro #testimonialwrap #testimonialcarousel .carousel-inner .item .carousel-caption .support .btn:hover {
                color: #ebeadc; }
    main#content article.homeintro #testimonialwrap .support {
      text-align: center;
      margin: 0 0 40px 0; }
      main#content article.homeintro #testimonialwrap .support .btn {
        background: url("/assets/static/images/box-csf-red-small.png");
        background-size: cover;
        color: #fff;
        text-transform: uppercase;
        font-family: "Avenir Next LT W01 Bold";
        padding: 5px 20px;
        font-size: 1.2em;
        border: 0;
        white-space: normal; }
        main#content article.homeintro #testimonialwrap .support .btn:hover {
          color: #ebeadc; }
    main#content article.intro {
      width: 100%;
      padding: 15px 15px;
      /* padding: 50px 15px; */ }
      @media only screen and (min-width: 768px) {
        main#content article.intro {
          padding: 0 25% 50px 25%; } }
      @media only screen and (min-width: 1900px) {
        main#content article.intro {
          padding: 0 25% 100px 25%; } }
      main#content article.intro h1 {
        padding: 0.5em 0 0 0; }
        @media only screen and (min-width: 768px) {
          main#content article.intro h1 {
            padding: 0; } }
      main#content article.intro input {
        margin: 0 1em 1em 0; }
        @media only screen and (min-width: 768px) {
          main#content article.intro input {
            margin: 0 1em 0 0; } }
      main#content article.intro form {
        margin: 0; }
        main#content article.intro form .form-control {
          color: #282828;
          font-size: 17px;
          padding: 6px; }
    main#content article.pagenonavi {
      margin: 0 0 0 0; }
      @media only screen and (min-width: 992px) {
        main#content article.pagenonavi {
          margin: 0 0 0 25%; } }
    main#content article.introdirect {
      width: 100%;
      padding: 50px 15px; }
      @media only screen and (min-width: 768px) {
        main#content article.introdirect {
          padding: 50px 25% 50px 25%; } }
      main#content article.introdirect h1 {
        padding: 0; }
    main#content article#unsere-partner section.articletext p, main#content article#our-partners section.articletext p {
      text-align: center; }
    main#content article section.articletext {
      padding: 15px 0; }
      @media only screen and (min-width: 768px) {
        main#content article section.articletext {
          padding: 0 50px 50px; } }
      @media only screen and (min-width: 768px) {
        main#content article section.articletext.intro {
          padding: 50px 50px 0 50px; }
          main#content article section.articletext.intro h1 {
            margin: 5px 0 20px 0; } }
      main#content article section.articletext.mainpage h1 {
        color: #d8292d;
        line-height: 1em; }
        @media only screen and (min-width: 768px) {
          main#content article section.articletext.mainpage h1 {
            text-align: center;
            margin: 5px auto 50px auto;
            font-size: 4em; } }
        @media only screen and (min-width: 992px) {
          main#content article section.articletext.mainpage h1 {
            font-size: 4.6666em; } }
        @media only screen and (min-width: 1200px) {
          main#content article section.articletext.mainpage h1 {
            width: 66%; } }
      main#content article section.articletext h1, main#content article section.articletext h2, main#content article section.articletext h3 {
        position: relative;
        padding: 0;
        color: #282828;
        line-height: 1.3em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;
        font-style: normal;
        font-family: "Avenir Next LT W01 Demi"; }
      main#content article section.articletext h1 {
        margin: 0 0 1em 0;
        padding: 0;
        font-size: 3em;
        color: #282828;
        line-height: 1.2em;
        text-align: left;
        width: 100%;
        letter-spacing: 0.02em;
        line-height: 1.2em;
        text-align: center;
        font-family: "Avenir Next LT W01 Bold"; }
        main#content article section.articletext h1 img.titleicon {
          height: 75px;
          width: auto;
          margin: 0 0 25px 0; }
        main#content article section.articletext h1 span {
          font-size: 0.7em; }
        @media only screen and (min-width: 768px) {
          main#content article section.articletext h1 {
            text-align: center;
            margin: 5px 0 50px 0;
            font-size: 4em; } }
        @media only screen and (min-width: 992px) {
          main#content article section.articletext h1 {
            font-size: 4.6666em; } }
      main#content article section.articletext h2 {
        margin: 1em 0 0 0;
        font-size: 2em;
        color: #111;
        line-height: 1.3em; }
        @media only screen and (min-width: 768px) {
          main#content article section.articletext h2 {
            font-size: 2em; } }
        @media only screen and (min-width: 992px) {
          main#content article section.articletext h2 {
            font-size: 2em; } }
      main#content article section.articletext h3 {
        margin: 1em 0;
        font-size: 1.2em;
        color: #333;
        text-transform: uppercase;
        line-height: 1em; }
      main#content article section.articletext.lead p {
        font-family: "Avenir Next LT W01 Demi";
        font-size: 1.6em;
        line-height: 1.3em;
        margin: 1em 0 0.5em 0; }
      @media only screen and (min-width: 768px) {
        main#content article section.articletext.lead {
          font-size: 1.2em; } }
      main#content article section.articletext p {
        margin: 0 0 1em 0;
        width: auto;
        font-size: 1em;
        color: #282828;
        padding-bottom: 0.1em;
        letter-spacing: 0.01em;
        line-height: 170%; }
        @media only screen and (max-width: 767px) {
          main#content article section.articletext p {
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto;
            -webkit-hyphenate-before: 2;
            -webkit-hyphenate-after: 3;
            hyphenate-lines: 3; } }
        @media only screen and (min-width: 768px) {
          main#content article section.articletext p {
            font-size: 1.2em; } }
        @media only screen and (min-width: 992px) {
          main#content article section.articletext p {
            font-size: 1.2em; } }
        main#content article section.articletext p.lead {
          font-family: "Avenir Next LT W01 Demi";
          font-size: 1.6em;
          line-height: 1.3em;
          margin: 1em 0 0.5em 0; }
          @media only screen and (min-width: 992px) {
            main#content article section.articletext p.lead {
              font-size: 1.2em;
              padding: 70px 80px; } }
        main#content article section.articletext p.bodylead {
          font-family: "Avenir Next LT W01 Demi";
          font-size: 1.4em;
          line-height: 150%;
          margin: 1em 0 0.5em 0; }
          @media only screen and (min-width: 768px) {
            main#content article section.articletext p.bodylead {
              font-size: 1.9em;
              padding: 0 0; } }
        main#content article section.articletext p a {
          color: #282828;
          text-decoration: none !important;
          font-weight: normal;
          text-transform: none;
          border-bottom: 1px solid #b2b2b2; }
          main#content article section.articletext p a:hover {
            color: #b2b2b2;
            text-decoration: none; }
          main#content article section.articletext p a:active {
            color: #d8292d !important; }
          main#content article section.articletext p a.sel {
            color: #282828; }
      main#content article section.articletext ul {
        color: #282828;
        margin: 0 0 1em 0.6em;
        padding-left: 0;
        text-indent: -0.75em;
        text-align: left; }
        @media only screen and (min-width: 768px) {
          main#content article section.articletext ul {
            text-indent: -0.85em; } }
        @media only screen and (min-width: 992px) {
          main#content article section.articletext ul {
            text-indent: -1em; } }
        main#content article section.articletext ul li {
          line-height: 140%;
          padding: 0.1em 0;
          font-size: 0.9em;
          list-style: none; }
          main#content article section.articletext ul li:before {
            content: "\003E \0020"; }
          @media only screen and (min-width: 768px) {
            main#content article section.articletext ul li {
              font-size: 1em; } }
          @media only screen and (min-width: 992px) {
            main#content article section.articletext ul li {
              font-size: 1em; } }
      main#content article section.articletext .donationtestimonial {
        background-color: #ebeadc;
        padding: 15px;
        margin: 30px 0;
        text-align: center;
        overflow: hidden; }
        @media only screen and (min-width: 768px) {
          main#content article section.articletext .donationtestimonial {
            padding: 30px; } }
        main#content article section.articletext .donationtestimonial img {
          border-radius: 50%;
          margin: 0 auto; }
        main#content article section.articletext .donationtestimonial .testimonial {
          text-align: left;
          font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif; }
          main#content article section.articletext .donationtestimonial .testimonial p {
            font-size: 1.5em;
            line-height: 1.3em; }
            @media only screen and (min-width: 768px) {
              main#content article section.articletext .donationtestimonial .testimonial p {
                font-size: 1.6em; } }
        main#content article section.articletext .donationtestimonial .testimonialinfo h3 {
          text-transform: uppercase;
          font-family: "Avenir Next LT W01 Bold";
          font-size: 1.1em;
          margin: 1.5em 0 0 0; }
        main#content article section.articletext .donationtestimonial .testimonialinfo p {
          font-size: 1.4em;
          line-height: 1.2em;
          margin: 1em 0; }
          @media only screen and (min-width: 768px) {
            main#content article section.articletext .donationtestimonial .testimonialinfo p {
              font-size: 1.6em; } }
        main#content article section.articletext .donationtestimonial .testimonialinfo div.info {
          font-size: 1em;
          color: #282828;
          margin: 1em 0 3em 0;
          font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif;
          line-height: 1.4em; }
          main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul {
            color: #282828;
            margin: 0 0 1em 0.6em;
            padding-left: 0;
            text-indent: -0.75em; }
            @media only screen and (min-width: 768px) {
              main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul {
                text-indent: -0.85em; } }
            @media only screen and (min-width: 992px) {
              main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul {
                text-indent: -1em; } }
            main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul li {
              line-height: 140%;
              padding: 0.1em 0;
              font-size: 0.9em;
              list-style: none; }
              main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul li:before {
                content: "\003E \0020"; }
              @media only screen and (min-width: 768px) {
                main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul li {
                  font-size: 1em; } }
              @media only screen and (min-width: 992px) {
                main#content article section.articletext .donationtestimonial .testimonialinfo div.info ul li {
                  font-size: 1em; } }
        main#content article section.articletext .donationtestimonial .btn {
          background: url("/assets/static/images/box-csf-red-small.png");
          background-size: cover;
          color: #fff;
          text-transform: uppercase;
          font-family: "Avenir Next LT W01 Bold";
          padding: 5px 20px;
          font-size: 1.1em; }
          @media only screen and (min-width: 768px) {
            main#content article section.articletext .donationtestimonial .btn {
              float: none; } }
          @media only screen and (min-width: 992px) {
            main#content article section.articletext .donationtestimonial .btn {
              float: none; } }
          main#content article section.articletext .donationtestimonial .btn:hover {
            color: #ebeadc; }
    main#content article section.features {
      background: #ebeadc;
      overflow: hidden;
      border-radius: 0 0 2px 2px; }
      main#content article section.features article {
        margin: 0;
        padding: 15px 15px;
        background: transparent; }
        main#content article section.features article h1 {
          margin: 0 0 1em 0;
          padding: 0;
          font-size: 2em;
          color: #282828;
          line-height: 1.2em;
          text-align: left;
          width: 100%;
          letter-spacing: 0.02em;
          text-transform: uppercase; }
        main#content article section.features article h2 {
          margin: 1em 0 0 0;
          font-size: 1.5em;
          color: #111;
          line-height: 1.3em;
          text-transform: uppercase; }
        main#content article section.features article ul {
          color: #282828;
          margin: 1em 0em 1em 1em;
          text-indent: -0.9em; }
          main#content article section.features article ul li {
            line-height: 160%;
            padding: 0.25em 0 0.25em 0;
            font-size: 1.2em;
            list-style-image: url(https://www.cuisinesansfrontieres.ch/library/images/red-square.png); }
            main#content article section.features article ul li a {
              font-size: 1.3333em;
              letter-spacing: 0.05em; }
        main#content article section.features article p {
          margin: 1em 0 0 0;
          width: auto;
          font-size: 0.9em;
          line-height: 180%;
          color: #282828;
          padding-bottom: 0.1em; }
          @media only screen and (min-width: 768px) {
            main#content article section.features article p {
              font-size: 0.9em; } }
          @media only screen and (min-width: 992px) {
            main#content article section.features article p {
              font-size: 0.9em; } }
          main#content article section.features article p.lead {
            margin: 0.2em 0 0.5em 0;
            font-size: 1.4em;
            font-weight: 200;
            line-height: 160%; }
          main#content article section.features article p a {
            color: #282828;
            text-decoration: none !important;
            text-transform: none;
            border-bottom: 1px solid #b2b2b2; }
            main#content article section.features article p a:hover {
              color: #000;
              text-decoration: none; }
            main#content article section.features article p a:active {
              color: #d8292d !important; }
            main#content article section.features article p a.sel {
              color: #282828; }
      main#content article section.features article + article {
        border-top: 1px solid #ddd; }
      main#content article section.features .buttonbox {
        text-align: center;
        width: 100%; }
        main#content article section.features .buttonbox .button {
          color: #d8292d !important;
          border: 1px solid #d8292d !important;
          padding: 0.3em 3em;
          font-size: 1.5em;
          float: none;
          margin: 1em 0 2em 0;
          letter-spacing: 0.05em;
          white-space: nowrap;
          border-radius: 2px;
          text-align: center;
          display: inline-block; }
          main#content article section.features .buttonbox .button:hover {
            color: #000 !important;
            border: 1px solid #000 !important; }
    main#content article aside.articleimages img {
      width: 100%;
      height: auto;
      border-radius: 2px 2px 0 0;
      margin: 0 0 25px 0; }
    main#content article aside.articleimages .carousel {
      text-align: center;
      background: #333;
      overflow: hidden; }
      main#content article aside.articleimages .carousel .carousel-caption {
        float: left;
        position: relative;
        width: 100%;
        height: auto;
        top: auto;
        left: auto;
        right: auto;
        bottom: 0;
        background: #333;
        margin: 0 0 0 0;
        padding: 0.6em 1em 1em 1em; }
        @media only screen and (min-width: 768px) {
          main#content article aside.articleimages .carousel .carousel-caption {
            display: table;
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            padding: 1em 0.5em;
            width: 36%;
            min-height: 80px;
            height: auto;
            top: 38%;
            left: 32%;
            font-size: 0.7em;
            line-height: 1.4em; } }
        @media only screen and (min-width: 992px) {
          main#content article aside.articleimages .carousel .carousel-caption {
            width: 36%;
            height: auto;
            top: 40%;
            left: 32%;
            font-size: 1em;
            line-height: 1.3em; } }
        @media only screen and (min-width: 1200px) {
          main#content article aside.articleimages .carousel .carousel-caption {
            width: 36%;
            height: auto;
            top: 42%;
            left: 32%;
            font-size: 1.1em;
            line-height: 1.3em; } }
        @media only screen and (min-width: 2100px) {
          main#content article aside.articleimages .carousel .carousel-caption {
            width: 30%;
            height: auto;
            top: 44%;
            left: 35%; } }
        @media only screen and (min-width: 768px) {
          main#content article aside.articleimages .carousel .carousel-caption .captiontext {
            display: table-cell;
            vertical-align: middle; } }
        main#content article aside.articleimages .carousel .carousel-caption h1, main#content article aside.articleimages .carousel .carousel-caption p {
          color: #fff;
          text-transform: uppercase;
          text-shadow: none;
          font-size: 1em;
          line-height: 1.5em;
          margin: 0;
          padding: 0;
          text-align: center; }
        main#content article aside.articleimages .carousel .carousel-caption p {
          font-size: 0.7em;
          letter-spacing: 0.1em; }
          main#content article aside.articleimages .carousel .carousel-caption p a {
            color: #ddd;
            border-bottom: 1px solid #999; }
            main#content article aside.articleimages .carousel .carousel-caption p a:hover {
              color: #fff;
              border-bottom: 1px solid #fff; }
        main#content article aside.articleimages .carousel .carousel-caption img {
          background: none;
          width: 40%;
          max-width: 230px;
          height: auto; }
          @media only screen and (min-width: 768px) {
            main#content article aside.articleimages .carousel .carousel-caption img {
              width: 100%;
              max-width: 230px;
              height: auto; } }
        main#content article aside.articleimages .carousel .carousel-caption.seethrough {
          background: rgba(255, 255, 255, 0.8); }
          main#content article aside.articleimages .carousel .carousel-caption.seethrough h1, main#content article aside.articleimages .carousel .carousel-caption.seethrough p {
            color: #282828; }
          main#content article aside.articleimages .carousel .carousel-caption.seethrough p a {
            color: #333;
            border-bottom: 1px solid #666; }
            main#content article aside.articleimages .carousel .carousel-caption.seethrough p a:hover {
              color: #000;
              border-bottom: 1px solid #000; }
      main#content article aside.articleimages .carousel a.imagelink:hover .carousel-caption {
        background: #a30b2f; }
      main#content article aside.articleimages .carousel .carousel-indicators li {
        margin: 1px 4px; }
        main#content article aside.articleimages .carousel .carousel-indicators li.active {
          margin: 0px 3px; }
    main#content article.feature {
      margin-bottom: 20px; }
      @media only screen and (min-width: 768px) {
        main#content article.feature {
          margin: 50px 16.6666%; } }
      main#content article.feature .articletext {
        text-align: left; }
        main#content article.feature .articletext .form-group {
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle; }
          main#content article.feature .articletext .form-group input {
            margin-bottom: 1em; }
      main#content article.feature aside.articleimages img {
        width: 100%;
        height: auto; }
      main#content article.feature aside.articleimages .carousel {
        text-align: center;
        background: #333; }
        main#content article.feature aside.articleimages .carousel .carousel-caption {
          float: left;
          position: relative;
          width: 100%;
          height: auto;
          top: auto;
          left: auto;
          right: auto;
          bottom: 0;
          background: #333;
          margin: 0 0 0 0;
          padding: 1em; }
          @media only screen and (min-width: 768px) {
            main#content article.feature aside.articleimages .carousel .carousel-caption {
              display: table;
              position: absolute;
              padding: 0.5em;
              background: rgba(0, 0, 0, 0.8);
              color: #fff;
              width: 36%;
              min-height: 80px;
              height: auto;
              top: 38%;
              left: 32%;
              font-size: 0.7em;
              line-height: 1.4em; } }
          @media only screen and (min-width: 992px) {
            main#content article.feature aside.articleimages .carousel .carousel-caption {
              width: 30%;
              height: auto;
              top: 40%;
              left: 35%;
              font-size: 1em;
              line-height: 1.3em; } }
          @media only screen and (min-width: 1200px) {
            main#content article.feature aside.articleimages .carousel .carousel-caption {
              width: 30%;
              height: auto;
              top: 42%;
              left: 35%;
              font-size: 1.1em;
              line-height: 1.3em; } }
          @media only screen and (min-width: 2100px) {
            main#content article.feature aside.articleimages .carousel .carousel-caption {
              width: 30%;
              height: auto;
              top: 44%;
              left: 35%; } }
          @media only screen and (min-width: 768px) {
            main#content article.feature aside.articleimages .carousel .carousel-caption .captiontext {
              display: table-cell;
              vertical-align: middle; } }
          main#content article.feature aside.articleimages .carousel .carousel-caption h1, main#content article.feature aside.articleimages .carousel .carousel-caption p {
            color: #fff;
            text-transform: uppercase;
            text-shadow: none;
            font-size: 1.2em;
            line-height: 1.5em;
            text-align: center; }
          main#content article.feature aside.articleimages .carousel .carousel-caption p {
            font-size: 0.7em;
            letter-spacing: 0.1em; }
            main#content article.feature aside.articleimages .carousel .carousel-caption p a {
              color: #ddd;
              border-bottom: 1px solid #999; }
              main#content article.feature aside.articleimages .carousel .carousel-caption p a:hover {
                color: #fff;
                border-bottom: 1px solid #fff; }
            main#content article.feature aside.articleimages .carousel .carousel-caption p.date {
              margin: 0.5em 0 0 0; }
          main#content article.feature aside.articleimages .carousel .carousel-caption.seethrough {
            background: rgba(255, 255, 255, 0.8); }
            main#content article.feature aside.articleimages .carousel .carousel-caption.seethrough h1, main#content article.feature aside.articleimages .carousel .carousel-caption.seethrough p {
              color: #282828; }
        main#content article.feature aside.articleimages .carousel a.imagelink:hover .carousel-caption {
          background: #a30b2f; }
    main#content article.gallerythumbs {
      margin-bottom: 20px; }
      @media only screen and (min-width: 992px) {
        main#content article.gallerythumbs {
          margin: 50px 16.6666%; } }
      main#content article.gallerythumbs .articletext {
        width: 100%;
        padding: 30px 0;
        text-align: center; }
        main#content article.gallerythumbs .articletext .form-group {
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle; }
          main#content article.gallerythumbs .articletext .form-group input {
            margin-bottom: 1em; }
        @media only screen and (min-width: 992px) {
          main#content article.gallerythumbs .articletext {
            padding: 26px 25% 0 25%; } }
      main#content article.gallerythumbs aside.articleimages {
        padding: 0 5px; }
        main#content article.gallerythumbs aside.articleimages figure {
          padding: 5px;
          text-align: center; }
          main#content article.gallerythumbs aside.articleimages figure img {
            border: 0 solid #fff;
            width: 100%;
            height: auto; }
    main#content article.directions .map #map_canvas {
      width: 100%;
      overflow: hidden;
      position: relative;
      padding-bottom: 66.66%;
      height: 0;
      overflow: hidden; }
      main#content article.directions .map #map_canvas img {
        max-width: none; }
      main#content article.directions .map #map_canvas #markertext {
        text-align: center; }
        main#content article.directions .map #map_canvas #markertext h1 {
          font-size: 1.3em;
          margin: 0 0 0 0;
          padding: 0;
          text-align: center; }
        main#content article.directions .map #map_canvas #markertext p {
          font-size: 1.1em;
          margin-bottom: 0; }
    main#content article.directions .map #address {
      position: relative;
      top: 0;
      right: 0;
      background: #333;
      width: 100%;
      height: 0;
      padding: 45% 0 55% 0;
      text-align: center;
      z-index: 9000;
      float: none;
      margin: 0 auto 2em auto;
      font-size: 1.3em; }
      @media only screen and (min-width: 320px) {
        main#content article.directions .map #address {
          width: 250px;
          height: 190px;
          padding: 20px 0;
          font-size: 1.4em; } }
      @media only screen and (min-width: 768px) {
        main#content article.directions .map #address {
          float: none;
          width: 270px;
          height: 190px;
          padding: 30px 0;
          margin: 40px auto;
          overflow: hidden;
          font-size: 1em; } }
      @media only screen and (min-width: 992px) {
        main#content article.directions .map #address {
          position: absolute;
          float: none;
          top: auto;
          right: 26%;
          bottom: 90px;
          width: 240px;
          height: 155px;
          padding: 10px 0;
          font-size: 1.1em;
          margin: 0 20px 0 0; } }
      main#content article.directions .map #address h1 {
        font-size: 1.2em;
        margin: 0;
        color: #fff;
        text-align: center !important; }
        main#content article.directions .map #address h1 span {
          padding: 0 0.1em; }
      main#content article.directions .map #address p {
        margin: 0.2em 0 0 0;
        font-size: 0.8em;
        color: #fff; }
        main#content article.directions .map #address p a {
          border: 0;
          color: #eee; }
          main#content article.directions .map #address p a:hover {
            color: #fff;
            border-bottom: 1px solid #fff;
            background: none; }
    main#content article.mediadownloads {
      width: 100%;
      padding: 15px 15px 0 15px; }
      @media only screen and (min-width: 768px) {
        main#content article.mediadownloads {
          padding: 0 25% 30px 25%; } }
      main#content article.mediadownloads h1 {
        text-transform: none;
        font-size: 1.2em;
        border: 0; }
      main#content article.mediadownloads h2 {
        font-size: 0.8em;
        margin: 0.5em 0; }
      main#content article.mediadownloads p {
        font-size: 0.8em;
        margin: 0.5em 0; }
        main#content article.mediadownloads p a {
          color: #333;
          border-bottom: 1px solid #666; }
          main#content article.mediadownloads p a:hover {
            color: #000;
            border-bottom: 1px solid #000; }
      main#content article.mediadownloads ul {
        list-style-type: none;
        font-size: 1.2em;
        margin-left: 0;
        text-indent: 0; }
        main#content article.mediadownloads ul li {
          padding: 0.5em 0; }
          main#content article.mediadownloads ul li a {
            color: #333;
            border-bottom: 1px solid #666; }
            main#content article.mediadownloads ul li a:hover {
              color: #000;
              border-bottom: 1px solid #000; }
          main#content article.mediadownloads ul li:before {
            content: none !important; }
  main#content section#projektueberblick h1 {
    font-size: 3em; }
  main#content section#projektueberblick .projekt {
    float: left;
    display: inline;
    margin: 0 0 40px 0;
    border: 0px dotted red; }
    @media only screen and (min-width: 992px) {
      main#content section#projektueberblick .projekt {
        margin: 0 0 50px 0; } }
    main#content section#projektueberblick .projekt a.trigger {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); }
      main#content section#projektueberblick .projekt a.trigger header {
        position: absolute;
        top: -15px;
        width: 100%;
        text-align: center;
        z-index: 1000; }
        main#content section#projektueberblick .projekt a.trigger header h2 {
          margin: 0;
          font-size: 2em;
          font-weight: normal;
          font-style: normal;
          color: #fff;
          background: url("/assets/static/images/box-csf-black-big.png");
          background-size: cover;
          display: inline;
          padding: 2px 10px;
          font-family: "Avenir Next LT W01 Bold";
          text-transform: uppercase; }
          @media only screen and (min-width: 768px) {
            main#content section#projektueberblick .projekt a.trigger header h2 {
              font-size: 2em; } }
        main#content section#projektueberblick .projekt a.trigger header img {
          width: 116px;
          height: auto;
          top: 35px;
          right: 5px;
          position: absolute; }
      main#content section#projektueberblick .projekt a.trigger img {
        width: 100%;
        height: auto;
        vertical-align: top;
        border: 0;
        opacity: 1; }
        @media only screen and (min-width: 992px) {
          main#content section#projektueberblick .projekt a.trigger img {
            opacity: 1; } }
      main#content section#projektueberblick .projekt a.trigger .projektinfo {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 100;
        background: #fff;
        opacity: 1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        text-align: center;
        float: left;
        padding: 0.8em 0 1em 0;
        margin-right: 15px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media only screen and (min-width: 992px) {
          main#content section#projektueberblick .projekt a.trigger .projektinfo {
            position: absolute;
            height: 100%;
            opacity: 0; } }
        main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext {
          float: left;
          position: relative;
          width: 100%;
          bottom: auto;
          margin-bottom: 0;
          overflow: visible;
          padding: 0 5px; }
          @media only screen and (min-width: 992px) {
            main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext {
              position: absolute;
              width: 100%;
              bottom: 50%;
              margin-bottom: -7px;
              overflow: hidden;
              padding: 20px 10px 5px 10px; } }
          @media only screen and (min-width: 1200px) {
            main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext {
              position: absolute;
              width: 100%;
              bottom: 50%;
              margin-bottom: -15px;
              overflow: hidden;
              padding: 20px 20px 10px 20px; } }
          main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext h1 {
            float: none;
            width: auto;
            height: auto;
            margin: 0.2em 0 0 0;
            font-size: 3em;
            font-weight: normal;
            font-style: normal;
            color: #fff;
            text-transform: uppercase; }
            @media only screen and (min-width: 992px) {
              main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext h1 {
                font-size: 3em; } }
            @media only screen and (min-width: 1200px) {
              main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext h1 {
                font-size: 3em; } }
          main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext h2 {
            margin: 0;
            font-size: 2em;
            font-weight: normal;
            font-style: normal;
            color: #fff;
            background: url("/assets/static/images/box-csf-black-big.png");
            background-size: cover; }
            @media only screen and (min-width: 768px) {
              main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext h2 {
                font-size: 2em; } }
          main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext p {
            color: #d8292d;
            font-size: 1.5em; }
          main#content section#projektueberblick .projekt a.trigger .projektinfo .projekttext .cats {
            color: #fff;
            font-size: 0.8em;
            float: none;
            margin: 0; }
      main#content section#projektueberblick .projekt a.trigger:hover header h2 {
        background: url(../images/box-csf-red-big.png);
        background-size: cover;
        display: inline; }
      main#content section#projektueberblick .projekt a.trigger:hover header img {
        display: none; }
      @media only screen and (min-width: 992px) {
        main#content section#projektueberblick .projekt a.trigger:hover .projektinfo {
          opacity: 1; } }
      main#content section#projektueberblick .projekt a.trigger:hover .projektinfo .projekttext p {
        color: #d8292d; }
  main#content section#mitmachueberblick h1 {
    font-size: 3em; }
  main#content section#mitmachueberblick .mitmachen {
    float: left;
    display: inline;
    margin: 0 0 40px 0;
    border: 0px dotted red;
    min-height: 600px; }
    @media only screen and (min-width: 992px) {
      main#content section#mitmachueberblick .mitmachen {
        margin: 0 0 50px 0;
        min-height: auto; } }
    main#content section#mitmachueberblick .mitmachen a.trigger {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25); }
      main#content section#mitmachueberblick .mitmachen a.trigger header {
        position: absolute;
        top: -15px;
        width: 100%;
        text-align: center;
        z-index: 1000; }
        main#content section#mitmachueberblick .mitmachen a.trigger header h2 {
          margin: 0;
          font-size: 1.5em;
          font-weight: normal;
          font-style: normal;
          color: #fff;
          background: url("/assets/static/images/box-csf-black-big.png");
          background-size: cover;
          display: inline;
          padding: 2px 10px;
          font-family: "Avenir Next LT W01 Bold";
          text-transform: uppercase; }
          @media only screen and (min-width: 768px) {
            main#content section#mitmachueberblick .mitmachen a.trigger header h2 {
              font-size: 1.5em; } }
      main#content section#mitmachueberblick .mitmachen a.trigger img {
        width: 100%;
        height: auto;
        vertical-align: top;
        border: 0;
        opacity: 1; }
        @media only screen and (min-width: 992px) {
          main#content section#mitmachueberblick .mitmachen a.trigger img {
            opacity: 1; } }
      main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 100;
        background: #fff;
        opacity: 1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        text-align: center;
        float: left;
        padding: 0.8em 0 1em 0;
        margin-right: 15px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media only screen and (min-width: 992px) {
          main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo {
            position: absolute;
            height: 100%;
            opacity: 0; } }
        main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext {
          float: left;
          position: relative;
          width: 100%;
          bottom: auto;
          margin-bottom: 0;
          overflow: visible;
          padding: 0 5px; }
          @media only screen and (min-width: 992px) {
            main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext {
              position: absolute;
              width: 100%;
              bottom: 50%;
              margin-bottom: -7%;
              overflow: hidden;
              padding: 20px 10px 5px 10px; } }
          @media only screen and (min-width: 1200px) {
            main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext {
              position: absolute;
              width: 100%;
              bottom: 50%;
              margin-bottom: -15%;
              overflow: hidden;
              padding: 20px 20px 10px 20px; } }
          main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext h1 {
            float: none;
            width: auto;
            height: auto;
            margin: 0.2em 0 0 0;
            font-size: 3em;
            font-weight: normal;
            font-style: normal;
            color: #fff;
            text-transform: uppercase; }
            @media only screen and (min-width: 992px) {
              main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext h1 {
                font-size: 3em; } }
            @media only screen and (min-width: 1200px) {
              main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext h1 {
                font-size: 3em; } }
          main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext h2 {
            margin: 0;
            font-size: 1.5em;
            font-weight: normal;
            font-style: normal;
            color: #fff;
            background: url("/assets/static/images/box-csf-black-big.png");
            background-size: cover; }
            @media only screen and (min-width: 768px) {
              main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext h2 {
                font-size: 1.5em; } }
          main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext p {
            color: #d8292d;
            font-size: 1.5em;
            line-height: 1.3em; }
          main#content section#mitmachueberblick .mitmachen a.trigger .mitmachinfo .mitmachtext .cats {
            color: #fff;
            font-size: 0.8em;
            float: none;
            margin: 0; }
      main#content section#mitmachueberblick .mitmachen a.trigger:hover header h2 {
        background: url(../images/box-csf-red-big.png);
        background-size: cover;
        display: inline; }
      @media only screen and (min-width: 992px) {
        main#content section#mitmachueberblick .mitmachen a.trigger:hover .mitmachinfo {
          opacity: 1; } }
      main#content section#mitmachueberblick .mitmachen a.trigger:hover .mitmachinfo .mitmachtext p {
        color: #d8292d; }
  main#content header.hometitle h1 {
    font-family: "Avenir Next LT W01 Demi";
    background: none;
    text-transform: none;
    font-size: 1.6em;
    margin: 2em 0 0 0; }
    @media only screen and (min-width: 992px) {
      main#content header.hometitle h1 {
        font-size: 3em; } }
  main#content header.hometitle.important {
    text-align: center;
    position: relative;
    top: 0;
    margin: 0 auto;
    z-index: 1; }
    @media only screen and (min-width: 992px) {
      main#content header.hometitle.important {
        margin: 0 auto; } }
    main#content header.hometitle.important h1 {
      font-size: 1.6em;
      background: url("/assets/static/images/box-csf-black-big.png");
      background-size: cover;
      display: inline;
      color: #fff;
      text-transform: uppercase;
      font-family: "Avenir Next LT W01 Bold";
      padding: 5px 20px; }
      @media only screen and (min-width: 992px) {
        main#content header.hometitle.important h1 {
          font-size: 3em; } }
  main#content .tableinfos {
    position: static;
    float: left;
    z-index: 1;
    background-color: #fff;
    margin: 40px 0 40px 0;
    padding: 10px;
    text-align: left; }
    @media only screen and (min-width: 1250px) {
      main#content .tableinfos {
        padding: 20px;
        /*position: absolute; top: 100px; right: 50px; background: white; width: 33%; */ } }
    main#content .tableinfos h3 {
      font-family: "AvenirNextLTW01-Heavy";
      font-size: 2em;
      margin: 0 0 1em 0; }
    main#content .tableinfos th {
      font-family: "Avenir Next LT W01 Demi";
      padding: 0 1.5em 0.5em 0; }
    main#content .tableinfos th {
      padding: 0 1.5em 0.5em 0; }
    main#content .tableinfos td {
      line-height: 1.3em;
      padding: 0 1.5em 0.5em 0;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      -webkit-hyphenate-before: 2;
      -webkit-hyphenate-after: 3;
      hyphenate-lines: 3; }
  main#content .mapcontainer {
    position: relative;
    padding: 0 0 0 0;
    text-align: center; }
    main#content .mapcontainer .markerinfo {
      overflow: hidden; }
      main#content .mapcontainer .markerinfo .h1 {
        margin: 0.5em 0;
        font-size: 1.5em;
        font-family: "AvenirNextLTW01-Heavy";
        text-transform: uppercase;
        color: #d8292d; }
  main#content #donationbutton {
    text-align: center;
    margin: 0 auto;
    padding: 0 0 80px 0; }
    main#content #donationbutton .donationbtn {
      background: url("/assets/static/images/box-csf-red-small.png");
      background-size: cover;
      color: #fff;
      text-transform: uppercase;
      font-family: "AvenirNextLTW01-Heavy";
      padding: 12px 30px;
      font-size: 1.4em;
      margin: -15px;
      z-index: 10; }
      main#content #donationbutton .donationbtn:hover {
        color: #ebeadc; }
  main#content #newsletter {
    background-color: #ebeadc;
    background-image: url(https://www.cuisinesansfrontieres.ch/library/images/newsletter-map-blurred.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0; }
    main#content #newsletter .contentwrap {
      text-align: center; }
      @media only screen and (min-width: 768px) {
        main#content #newsletter .contentwrap {
          text-align: left; } }
    main#content #newsletter img {
      width: 294px;
      height: auto;
      float: none; }
      @media only screen and (min-width: 768px) {
        main#content #newsletter img {
          float: left; } }
    main#content #newsletter p.lead {
      font-family: "Avenir Next LT W01 Demi";
      background: none;
      text-transform: none;
      font-size: 2em;
      margin: 0.5em 0 1em 0;
      line-height: 1.3em; }
      main#content #newsletter p.lead img {
        width: 147px;
        height: auto;
        float: right; }
    main#content #newsletter .form-group {
      padding: 0;
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        main#content #newsletter .form-group {
          padding: 0 10px 0 0; } }
      main#content #newsletter .form-group .form-control {
        height: 45px; }
    main#content #newsletter .indicates-required {
      text-align: left;
      margin: 10px 0 0 0; }
    main#content #newsletter .btn {
      background: url("/assets/static/images/box-csf-red-small.png");
      background-size: cover;
      color: #fff;
      text-transform: uppercase;
      font-family: "Avenir Next LT W01 Bold";
      padding: 5px 20px;
      font-size: 1.2em;
      border: 0;
      white-space: normal;
      float: right;
      margin: 10px 0 0 0; }
      main#content #newsletter .btn:hover {
        color: #ebeadc; }
  main#content section#newscontainer header h1 {
    font-family: "Avenir Next LT W01 Demi";
    background: none;
    text-transform: none;
    font-size: 2em;
    margin: 2em 0 0 0; }
  main#content section#newscontainer .news {
    background: #fff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    margin: 60px 0 0 0; }
    main#content section#newscontainer .news header h1 {
      text-align: left;
      font-family: "Avenir Next LT W01 Demi";
      background: none;
      text-transform: none;
      font-size: 2em;
      margin: 15px 15px 30px; }
      @media only screen and (min-width: 768px) {
        main#content section#newscontainer .news header h1 {
          margin: 20px 30px 30px; } }
      @media only screen and (min-width: 992px) {
        main#content section#newscontainer .news header h1 {
          margin: 2em 3em; } }
    main#content section#newscontainer .news .newsimages {
      padding-left: 0; }
      main#content section#newscontainer .news .newsimages img {
        background: none;
        margin: 0 0 0 0;
        padding: 0 0 15px 15px; }
        @media only screen and (min-width: 992px) {
          main#content section#newscontainer .news .newsimages img {
            padding: 0 30px 0 0; } }
      @media only screen and (min-width: 992px) {
        main#content section#newscontainer .news .newsimages img + img {
          padding: 30px 30px 0 0; } }
    main#content section#newscontainer .news .articletext {
      padding: 15px 0; }
      @media only screen and (min-width: 768px) {
        main#content section#newscontainer .news .articletext {
          padding: 0 50px 50px; } }
      main#content section#newscontainer .news .articletext .locationdate {
        margin: 0; }
      main#content section#newscontainer .news .articletext .projectlink {
        float: right;
        font-family: "Avenir Next LT W01 Bold";
        color: #d8292d;
        border: 0;
        text-transform: uppercase; }
        main#content section#newscontainer .news .articletext .projectlink:hover {
          color: #282828; }
    main#content section#newscontainer .news.media .reportinfo {
      font-size: 1.2em;
      line-height: 1.3em;
      padding: 15px; }
      @media only screen and (min-width: 768px) {
        main#content section#newscontainer .news.media .reportinfo {
          padding: 2em; } }
      main#content section#newscontainer .news.media .reportinfo p strong {
        font-family: "Avenir Next LT W01 Bold";
        font-size: 0.9em;
        text-transform: uppercase;
        letter-spacing: 0.05em; }
    main#content section#newscontainer .news.media .newsimages img {
      background: none;
      margin: 0 0 25px 0; }
    main#content section#newscontainer .news.media .articletext {
      margin: 0;
      padding: 15px 0; }
      @media only screen and (min-width: 992px) {
        main#content section#newscontainer .news.media .articletext {
          margin: 2.5em 0;
          padding: 0 1em 0 0; } }
      main#content section#newscontainer .news.media .articletext h1 {
        font-family: "Avenir Next LT W01 Demi";
        background: none;
        text-transform: none;
        font-size: 2em;
        margin: 0 0 0.5em 0;
        text-align: left; }
      main#content section#newscontainer .news.media .articletext .locationdate {
        margin: 0; }
      main#content section#newscontainer .news.media .articletext .projectlink {
        float: right;
        font-family: "Avenir Next LT W01 Bold";
        color: #d8292d;
        border: 0;
        text-transform: uppercase; }
        main#content section#newscontainer .news.media .articletext .projectlink:hover {
          color: #282828; }
  main#content section#homepagenews, main#content section#relatednews {
    padding-top: 100px;
    padding-bottom: 100px; }
    main#content section#homepagenews header h1, main#content section#relatednews header h1 {
      font-family: "Avenir Next LT W01 Demi";
      background: none;
      text-transform: none;
      font-size: 2em;
      margin: 3em 0 0 0; }
    main#content section#homepagenews header.important, main#content section#relatednews header.important {
      text-align: center;
      position: relative;
      top: -12px;
      margin: 0 0 0 0; }
      main#content section#homepagenews header.important h1, main#content section#relatednews header.important h1 {
        font-size: 3em;
        background: url("/assets/static/images/box-csf-black-big.png");
        background-size: cover;
        display: inline;
        color: #fff;
        text-transform: uppercase;
        font-family: "Avenir Next LT W01 Bold";
        padding: 5px 20px; }
    main#content section#homepagenews .news, main#content section#relatednews .news {
      background: #fff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
      margin: 60px 0 0 0; }
      main#content section#homepagenews .news header h1, main#content section#relatednews .news header h1 {
        text-align: left;
        font-family: "Avenir Next LT W01 Demi";
        background: none;
        text-transform: none;
        font-size: 2em;
        margin: 0.8em 0 0.3em 0; }
        @media only screen and (min-width: 768px) {
          main#content section#homepagenews .news header h1, main#content section#relatednews .news header h1 {
            margin: 0.8em 0 0.3em 0; } }
        @media only screen and (min-width: 992px) {
          main#content section#homepagenews .news header h1, main#content section#relatednews .news header h1 {
            margin: 0.8em 0 0.3em 0; } }
      main#content section#homepagenews .news .newsimages, main#content section#relatednews .news .newsimages {
        padding: 0 15px 0 0; }
        main#content section#homepagenews .news .newsimages img, main#content section#relatednews .news .newsimages img {
          background: none;
          margin: 0 0 0 0;
          padding: 15px 0 15px 15px; }
          @media only screen and (min-width: 992px) {
            main#content section#homepagenews .news .newsimages img, main#content section#relatednews .news .newsimages img {
              padding: 0 30px 0 0; } }
        @media only screen and (min-width: 992px) {
          main#content section#homepagenews .news .newsimages img + img, main#content section#relatednews .news .newsimages img + img {
            padding: 30px 30px 0 0; } }
      main#content section#homepagenews .news .articletext, main#content section#relatednews .news .articletext {
        padding: 15px; }
        @media only screen and (min-width: 768px) {
          main#content section#homepagenews .news .articletext, main#content section#relatednews .news .articletext {
            padding: 15px; } }
        main#content section#homepagenews .news .articletext .locationdate, main#content section#relatednews .news .articletext .locationdate {
          margin: 0; }
        main#content section#homepagenews .news .articletext .moreinfo, main#content section#relatednews .news .articletext .moreinfo {
          font-family: "Avenir Next LT W01 Bold";
          color: #d8292d;
          border: 0;
          text-transform: uppercase; }
          main#content section#homepagenews .news .articletext .moreinfo:hover, main#content section#relatednews .news .articletext .moreinfo:hover {
            color: #282828; }
      main#content section#homepagenews .news.media .reportinfo, main#content section#relatednews .news.media .reportinfo {
        font-size: 1.2em;
        line-height: 1.3em; }
        @media only screen and (min-width: 768px) {
          main#content section#homepagenews .news.media .reportinfo, main#content section#relatednews .news.media .reportinfo {
            padding: 2em; } }
        main#content section#homepagenews .news.media .reportinfo p strong, main#content section#relatednews .news.media .reportinfo p strong {
          font-family: "Avenir Next LT W01 Bold";
          font-size: 0.9em;
          text-transform: uppercase;
          letter-spacing: 0.05em; }
      main#content section#homepagenews .news.media .newsimages img, main#content section#relatednews .news.media .newsimages img {
        background: none;
        margin: 0 0 0 0; }
      main#content section#homepagenews .news.media .articletext, main#content section#relatednews .news.media .articletext {
        margin: 0;
        padding: 15px; }
        @media only screen and (min-width: 992px) {
          main#content section#homepagenews .news.media .articletext, main#content section#relatednews .news.media .articletext {
            margin: 2.5em 0;
            padding: 0 1em 0 0; } }
        main#content section#homepagenews .news.media .articletext h1, main#content section#relatednews .news.media .articletext h1 {
          font-family: "Avenir Next LT W01 Demi";
          background: none;
          text-transform: none;
          font-size: 2em;
          margin: 0 0 0.5em 0;
          text-align: left; }
        main#content section#homepagenews .news.media .articletext .locationdate, main#content section#relatednews .news.media .articletext .locationdate {
          margin: 0; }
  main#content #textprojectmap .articletext {
    font-family: "Avenir Next LT W01 Demi"; }
    main#content #textprojectmap .articletext p {
      font-size: 1.5em;
      line-height: 1.4em;
      margin: 0 0 0.5em 0;
      font-size: 1.5em; }
      @media only screen and (min-width: 768px) {
        main#content #textprojectmap .articletext p {
          font-size: 1.5em; } }
      @media only screen and (min-width: 992px) {
        main#content #textprojectmap .articletext p {
          font-size: 2.2em; } }
  main#content section.homepagefeature {
    overflow: hidden;
    border-radius: 0 0 2px 2px;
    width: 100%;
    padding-top: 60px; }
    main#content section.homepagefeature article.feature {
      margin: 0;
      padding: 33px 15px 75px;
      background: transparent; }
      main#content section.homepagefeature article.feature:last-child {
        padding: 33px 15px 0; }
      @media only screen and (min-width: 768px) {
        main#content section.homepagefeature article.feature aside.articleimages {
          padding-left: 0; } }
      main#content section.homepagefeature article.feature aside.articleimages img {
        border-radius: 0;
        margin: 0 0 25px 0; }
        @media only screen and (min-width: 768px) {
          main#content section.homepagefeature article.feature aside.articleimages img {
            width: 100%;
            height: auto; } }
      main#content section.homepagefeature article.feature .articletext {
        font-family: "Avenir Next LT W01 Demi"; }
        main#content section.homepagefeature article.feature .articletext h1 {
          margin: 0 0 1em 0;
          padding: 0;
          font-size: 2em;
          color: #282828;
          line-height: 1.2em;
          text-align: left;
          width: 100%;
          letter-spacing: 0.02em;
          text-transform: uppercase; }
        main#content section.homepagefeature article.feature .articletext h2 {
          margin: 1em 0 0 0;
          font-size: 1.5em;
          color: #111;
          line-height: 1.3em;
          text-transform: uppercase; }
        main#content section.homepagefeature article.feature .articletext ul {
          color: #282828;
          margin: 0 0 1em 0.6em;
          padding-left: 0;
          text-indent: -0.75em; }
          @media only screen and (min-width: 768px) {
            main#content section.homepagefeature article.feature .articletext ul {
              text-indent: -0.85em; } }
          @media only screen and (min-width: 992px) {
            main#content section.homepagefeature article.feature .articletext ul {
              text-indent: -1em; } }
          main#content section.homepagefeature article.feature .articletext ul li {
            line-height: 140%;
            padding: 0.1em 0;
            font-size: 1.2em;
            list-style: none; }
            main#content section.homepagefeature article.feature .articletext ul li:before {
              content: "\003E \0020"; }
        main#content section.homepagefeature article.feature .articletext p {
          font-size: 1.5em;
          line-height: 1.4em;
          margin: 0 0 1em 0;
          width: auto;
          color: #282828;
          padding-bottom: 0.1em; }
          main#content section.homepagefeature article.feature .articletext p.lead {
            margin: 0.2em 0 0.5em 0;
            font-size: 1.4em;
            font-weight: 200;
            line-height: 160%; }
          main#content section.homepagefeature article.feature .articletext p.info {
            font-size: 1.5em;
            color: #282828;
            margin: 2em 0 4em;
            padding: 0;
            font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif;
            line-height: 1.4em; }
          main#content section.homepagefeature article.feature .articletext p a {
            color: #282828;
            text-decoration: none !important;
            text-transform: none;
            border-bottom: 1px solid #b2b2b2; }
            main#content section.homepagefeature article.feature .articletext p a:hover {
              color: #000;
              text-decoration: none; }
            main#content section.homepagefeature article.feature .articletext p a:active {
              color: #d8292d !important; }
            main#content section.homepagefeature article.feature .articletext p a.sel {
              color: #282828; }
            main#content section.homepagefeature article.feature .articletext p a.btn {
              background: url("/assets/static/images/box-csf-red-small.png");
              background-size: cover;
              font-family: "AvenirNextLTW01-Heavy";
              color: #fff;
              text-transform: uppercase;
              font-size: 0.6em; }
    main#content section.homepagefeature article + article {
      border-top: 1px solid #ddd; }
    main#content section.homepagefeature .buttonbox {
      text-align: center;
      width: 100%; }
      main#content section.homepagefeature .buttonbox .button {
        color: #d8292d !important;
        border: 1px solid #d8292d !important;
        padding: 0.3em 3em;
        font-size: 1.5em;
        float: none;
        margin: 1em 0 2em 0;
        letter-spacing: 0.05em;
        white-space: nowrap;
        border-radius: 2px;
        text-align: center;
        display: inline-block; }
        main#content section.homepagefeature .buttonbox .button:hover {
          color: #000 !important;
          border: 1px solid #000 !important; }
  main#content .button {
    display: inline-block;
    padding: 10px 18px;
    margin-bottom: 10px;
    background: #ebeadc;
    border: none;
    border-radius: 1px;
    color: #282828;
    font-family: "AvenirNextLTW01-Heavy";
    letter-spacing: 0.05em;
    font-size: 16px;
    cursor: pointer; }
  main#content .button:hover {
    background-color: #d8292d;
    color: #fff; }
  main#content .button:active,
  main#content .button.is-checked {
    background-color: #d8292d; }
  main#content .button.is-checked {
    color: white; }
  main#content .button-group:after {
    content: '';
    display: block;
    clear: both; }
  main#content .button-group .button {
    float: NONE;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0; }
  main#content .button-group .button:first-child {
    border-radius: 0 0 0 0; }
  main#content .button-group .button:last-child {
    border-radius: 0 0 0 0; }
  main#content .contentfilter {
    text-align: center; }
  main#content #personen {
    padding: 0; }
    main#content #personen .person {
      height: auto;
      padding: 8px; }
      @media only screen and (min-width: 481px) and (max-width: 767px) {
        main#content #personen .person {
          width: 50%; }
          main#content #personen .person.featured {
            width: 100%; } }
      @media only screen and (min-width: 768px) {
        main#content #personen .person {
          height: auto; } }
      @media only screen and (min-width: 992px) {
        main#content #personen .person {
          height: auto; } }
      main#content #personen .person .personcontent {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
        padding: 15px 20px;
        height: auto;
        text-align: center;
        overflow: hidden; }
        @media only screen and (min-width: 768px) {
          main#content #personen .person .personcontent {
            height: auto; } }
        @media only screen and (min-width: 992px) {
          main#content #personen .person .personcontent {
            height: auto; } }
        main#content #personen .person .personcontent .featuredtestimonial {
          font-size: 1.5em;
          line-height: 1.3em;
          /* min-height: 250px; */ }
        main#content #personen .person .personcontent img {
          border-radius: 50%;
          max-height: 152px; }
        main#content #personen .person .personcontent h3 {
          text-transform: uppercase;
          font-family: "Avenir Next LT W01 Bold";
          font-size: 1.1em;
          margin: 1.5em 0 0 0;
          text-align: left; }
        main#content #personen .person .personcontent div {
          margin: 1em 0 1em 0;
          text-align: left; }
          main#content #personen .person .personcontent div.info {
            line-height: 1.3em; }
            main#content #personen .person .personcontent div.info ul {
              color: #282828;
              margin: 0 0 1em 0.6em;
              padding-left: 0;
              text-indent: -0.75em; }
              @media only screen and (min-width: 768px) {
                main#content #personen .person .personcontent div.info ul {
                  text-indent: -0.85em; } }
              @media only screen and (min-width: 992px) {
                main#content #personen .person .personcontent div.info ul {
                  text-indent: -1em; } }
              main#content #personen .person .personcontent div.info ul li {
                line-height: 140%;
                padding: 0.1em 0;
                font-size: 0.9em;
                list-style: none; }
                main#content #personen .person .personcontent div.info ul li:before {
                  content: "\003E \0020"; }
                @media only screen and (min-width: 768px) {
                  main#content #personen .person .personcontent div.info ul li {
                    font-size: 1em; } }
                @media only screen and (min-width: 992px) {
                  main#content #personen .person .personcontent div.info ul li {
                    font-size: 1em; } }
        main#content #personen .person .personcontent a.btn {
          background-color: #d8292d;
          width: 100%;
          background: url(../images/box-kb-beige.png);
          background-size: cover;
          font-family: "Avenir Next LT W01 Bold";
          color: #282828;
          text-transform: uppercase;
          font-size: 0.9em; }
          main#content #personen .person .personcontent a.btn:hover {
            color: #b2b2b2; }
      main#content #personen .person.featured {
        width: inherit200px; }
        main#content #personen .person.featured .personcontent img {
          border-radius: 50%;
          max-height: 250px; }
  main#content div.pagination {
    width: 100%;
    margin: 0 0 0 0;
    padding: 15px 15px;
    font-size: 1.4em;
    font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif;
    text-align: right; }
    main#content div.pagination#paginatebottom {
      margin: 40px 0 0 0; }
  main#content section.list {
    padding: 0;
    margin-top: 0; }
    main#content section.list article {
      padding: 0;
      margin: 0; }
      main#content section.list article .articleimages img {
        width: 100%;
        height: auto;
        background: #ccc; }
      main#content section.list article .articletext h1 {
        font-size: 1em;
        border: 0;
        line-height: 1.3em; }
      main#content section.list article .articletext h2 {
        font-size: 1em; }
      main#content section.list article .articletext ul {
        color: #282828;
        margin: 0 0 1em 0.6em;
        padding-left: 0;
        text-indent: -0.75em; }
        @media only screen and (min-width: 768px) {
          main#content section.list article .articletext ul {
            text-indent: -0.85em; } }
        @media only screen and (min-width: 992px) {
          main#content section.list article .articletext ul {
            text-indent: -1em; } }
        main#content section.list article .articletext ul li {
          line-height: 140%;
          padding: 0.1em 0;
          font-size: 0.9em;
          list-style: none; }
          main#content section.list article .articletext ul li:before {
            content: "\003E \0020"; }
          @media only screen and (min-width: 768px) {
            main#content section.list article .articletext ul li {
              font-size: 1em; } }
          @media only screen and (min-width: 992px) {
            main#content section.list article .articletext ul li {
              font-size: 1em; } }
      main#content section.list article section.features {
        background: #ebeadc;
        overflow: hidden; }
        main#content section.list article section.features h1 {
          margin: 0 0 1em 0;
          padding: 0;
          font-size: 2em;
          color: #282828;
          line-height: 1.2em;
          text-align: left;
          width: 100%;
          letter-spacing: 0.02em;
          text-transform: uppercase; }
        main#content section.list article section.features p {
          margin: 1em 0 0 0;
          width: auto;
          font-size: 0.9em;
          line-height: 180%;
          color: #282828;
          padding-bottom: 0.1em; }
          @media only screen and (min-width: 768px) {
            main#content section.list article section.features p {
              font-size: 0.9em; } }
          @media only screen and (min-width: 992px) {
            main#content section.list article section.features p {
              font-size: 0.9em; } }
          main#content section.list article section.features p.lead {
            margin: 0.2em 0 0.5em 0;
            font-size: 1.4em;
            font-weight: 200;
            line-height: 160%; }
          main#content section.list article section.features p a {
            color: #333;
            border-bottom: 1px solid #999; }
            main#content section.list article section.features p a:hover {
              color: #000;
              border-bottom: 1px solid #000; }
            main#content section.list article section.features p a.sel {
              color: #000;
              border-bottom: 1px solid #000; }
            main#content section.list article section.features p a.button {
              color: #d8292d !important;
              border: 1px solid #d8292d !important;
              padding: 0.3em 3em;
              font-size: 1.5em;
              float: left;
              margin: 2em 0 0 0;
              left: 0;
              letter-spacing: 0.05em;
              white-space: nowrap;
              border-radius: 2px; }
              main#content section.list article section.features p a.button:hover {
                color: #000 !important;
                border: 1px solid #000 !important; }
        main#content section.list article section.features ul {
          color: #282828;
          margin: 0 0 1em 0.6em;
          padding-left: 0;
          text-indent: -0.75em; }
          @media only screen and (min-width: 768px) {
            main#content section.list article section.features ul {
              text-indent: -0.85em; } }
          @media only screen and (min-width: 992px) {
            main#content section.list article section.features ul {
              text-indent: -1em; } }
          main#content section.list article section.features ul li {
            line-height: 140%;
            padding: 0.1em 0;
            font-size: 0.9em;
            list-style: none; }
            main#content section.list article section.features ul li:before {
              content: "\003E \0020"; }
            @media only screen and (min-width: 768px) {
              main#content section.list article section.features ul li {
                font-size: 1em; } }
            @media only screen and (min-width: 992px) {
              main#content section.list article section.features ul li {
                font-size: 1em; } }
        main#content section.list article section.features .specialprice {
          float: right;
          margin: 25px 0 0 0;
          font-size: 1.2em;
          text-transform: uppercase;
          letter-spacing: 0.05em; }
          main#content section.list article section.features .specialprice span {
            font-size: 3em; }
    main#content section.list.specials {
      padding: 0 0 0 0;
      margin-top: 0; }
      main#content section.list.specials article {
        position: static;
        margin: 0 0 2px 0; }
        main#content section.list.specials article .articletext h1 {
          margin: 0 0 0 0;
          padding: 0;
          font-size: 3em;
          color: #282828;
          line-height: 0.8em;
          text-align: left;
          width: 100%;
          letter-spacing: 0.02em; }
          @media only screen and (min-width: 768px) {
            main#content section.list.specials article .articletext h1 {
              text-align: center;
              margin: 5px 0 30px 0;
              font-size: 4em;
              line-height: 0.5em; } }
          @media only screen and (min-width: 992px) {
            main#content section.list.specials article .articletext h1 {
              font-size: 5em; } }
          main#content section.list.specials article .articletext h1 small {
            margin: 0;
            font-size: 0.5em;
            color: #282828;
            letter-spacing: 0.02em; }
            @media only screen and (min-width: 768px) {
              main#content section.list.specials article .articletext h1 small {
                font-size: 0.4em; } }
            @media only screen and (min-width: 992px) {
              main#content section.list.specials article .articletext h1 small {
                font-size: 0.3em; } }
        main#content section.list.specials article .articletext h2 {
          margin: 0.7em 0 0 0;
          text-transform: none; }
        main#content section.list.specials article .articletext p {
          margin: 0.5em 0 0 0; }
    main#content section.list.jobs, main#content section.list.media {
      width: 100%;
      padding: 0 0 0 0;
      margin-top: 0; }
      @media only screen and (min-width: 768px) {
        main#content section.list.jobs, main#content section.list.media {
          padding: 0; } }
      main#content section.list.jobs article, main#content section.list.media article {
        border-top: 1px solid #ddd;
        padding: 20px 15px;
        background: none; }
        @media only screen and (min-width: 768px) {
          main#content section.list.jobs article, main#content section.list.media article {
            padding: 20px 0; } }
        main#content section.list.jobs article .articleimages img, main#content section.list.media article .articleimages img {
          padding-bottom: 20px;
          width: 150px;
          height: auto; }
          @media only screen and (min-width: 768px) {
            main#content section.list.jobs article .articleimages img, main#content section.list.media article .articleimages img {
              width: 100%;
              height: auto; } }
        main#content section.list.jobs article .articletext h1, main#content section.list.jobs article .articletext h2, main#content section.list.media article .articletext h1, main#content section.list.media article .articletext h2 {
          text-align: left;
          color: #282828;
          font-size: 2em;
          border: 0;
          margin: 0;
          padding: 0; }
        main#content section.list.jobs article .articletext h2, main#content section.list.media article .articletext h2 {
          font-size: 1em;
          margin: 0.5em 0;
          line-height: 1.4em; }
        main#content section.list.jobs article .articletext p, main#content section.list.media article .articletext p {
          font-size: 1em;
          margin: 0.5em 0; }
          main#content section.list.jobs article .articletext p a, main#content section.list.media article .articletext p a {
            color: #d8292d;
            border-bottom: 1px solid #666; }
            main#content section.list.jobs article .articletext p a:hover, main#content section.list.media article .articletext p a:hover {
              color: #000;
              border-bottom: 1px solid #000; }
    main#content section.list.mediagallery {
      width: 100%;
      padding: 15px 15px; }
      @media only screen and (min-width: 768px) {
        main#content section.list.mediagallery {
          padding: 15px 0; } }
      main#content section.list.mediagallery h1 {
        text-transform: none;
        font-size: 1.2em;
        border: 0;
        width: 100%;
        padding: 2em 0 1em 0; }
        @media only screen and (min-width: 768px) {
          main#content section.list.mediagallery h1 {
            padding: 2em 25% 1em 25%; } }
      main#content section.list.mediagallery .row-fluid div {
        margin: 10px 0; }
      main#content section.list.mediagallery .carousel {
        height: auto;
        background: #fff;
        padding: 1em; }
        @media only screen and (min-width: 1300px) {
          main#content section.list.mediagallery .carousel {
            width: 1300px;
            margin: 0 auto; } }
        main#content section.list.mediagallery .carousel .carousel-indicators {
          padding: 0 0 0 0;
          bottom: 0; }
          main#content section.list.mediagallery .carousel .carousel-indicators li {
            border-color: #333;
            margin: 0 4px; }
            main#content section.list.mediagallery .carousel .carousel-indicators li.active {
              background: #333;
              width: 10px;
              height: 10px;
              border: 0;
              margin: 0 4px; }
      main#content section.list.mediagallery .articleimages img {
        width: 100%;
        height: auto; }
      main#content section.list.mediagallery .articletext h1, main#content section.list.mediagallery .articletext h2 {
        text-transform: none;
        font-size: 1em;
        border: 0; }
      main#content section.list.mediagallery .articletext h2 {
        font-size: 0.8em;
        margin: 0.5em 0; }
      main#content section.list.mediagallery .articletext p {
        font-size: 0.8em;
        margin: 0.5em 0; }
        main#content section.list.mediagallery .articletext p a {
          color: #333;
          border-bottom: 1px solid #666; }
          main#content section.list.mediagallery .articletext p a:hover {
            color: #000;
            border-bottom: 1px solid #000; }
  main#content #home {
    display: none;
    width: auto;
    height: auto;
    z-index: 0;
    position: relative;
    top: 0;
    left: 0; }
    @media only screen and (min-width: 992px) {
      main#content #home {
        width: 93%;
        height: 100%;
        z-index: 200000;
        position: absolute;
        top: 0;
        left: 60px; } }

/*______ Footer ______*/
footer {
  width: 100%;
  overflow: hidden;
  padding: 30px 0 0 0;
  margin: 50px 0 0 0;
  background: #ebeadc;
  border: 0 solid #000;
  font-size: 1.15em;
  line-height: 1.25em; }
  footer strong {
    font-family: "Avenir Next LT W01 Demi"; }
  @media only screen and (min-width: 992px) {
    footer {
      bottom: 0; } }

.contentwrap {
  max-width: 1200px;
  margin: 0 auto;
  clear: both; }
  .contentwrap.textonly {
    max-width: 768px;
    overflow: hidden; }

.footerwrap {
  display: block;
  margin: 0 auto 30px;
  border: 0 dotted DodgerBlue;
  overflow: hidden;
  padding: 15px 15px; }
  @media only screen and (min-width: 768px) {
    .footerwrap {
      font-size: 1em; } }
  @media only screen and (min-width: 992px) {
    .footerwrap .wrap {
      display: table; } }
  .footerwrap .wrap#nlsubscription {
    border: 0 dotted DodgerBlue;
    margin: 1em 0; }
    @media only screen and (min-width: 992px) {
      .footerwrap .wrap#nlsubscription {
        margin: 0 0 0 0; } }
    @media print {
      .footerwrap .wrap#nlsubscription {
        display: none; } }
    .footerwrap .wrap#nlsubscription .lead p {
      font-family: "Avenir Next LT W01 Demi";
      background: none;
      text-transform: none;
      font-size: 1.4em;
      margin: 0.5em 0 0 0;
      line-height: 1.3em; }
    .footerwrap .wrap#nlsubscription .lead img.nlimage {
      width: 147px;
      height: auto; }
      @media only screen and (min-width: 768px) {
        .footerwrap .wrap#nlsubscription .lead img.nlimage {
          position: static;
          float: right;
          clear: both; } }
    .footerwrap .wrap#nlsubscription form {
      margin: 15px 0 0 0; }
      .footerwrap .wrap#nlsubscription form h1 {
        margin: 0;
        font-size: 1.3333em;
        color: #b2b2b2;
        line-height: 1.3em;
        text-transform: uppercase; }
      .footerwrap .wrap#nlsubscription form .form-control {
        background-color: #fff;
        color: #282828;
        border-radius: 0;
        border: 0;
        height: 45px; }
      .footerwrap .wrap#nlsubscription form .form-group {
        padding: 0;
        margin-bottom: 5px; }
        @media only screen and (min-width: 768px) {
          .footerwrap .wrap#nlsubscription form .form-group {
            padding: 0 10px 0 0; } }
      .footerwrap .wrap#nlsubscription form .indicates-required {
        margin: 10px 0 0 0; }
      .footerwrap .wrap#nlsubscription form .btn {
        background: url("/assets/static/images/box-csf-red-small.png");
        background-size: cover;
        color: #fff;
        text-transform: uppercase;
        font-family: "Avenir Next LT W01 Bold";
        padding: 5px 20px;
        font-size: 1.2em;
        border: 0;
        white-space: normal;
        float: right;
        margin: 10px 0 0 0; }
        .footerwrap .wrap#nlsubscription form .btn:hover {
          color: #ebeadc; }
    .footerwrap .wrap#nlsubscription a, .footerwrap .wrap#nlsubscription button {
      color: #ddd; }
      .footerwrap .wrap#nlsubscription a:hover, .footerwrap .wrap#nlsubscription button:hover {
        color: #fff; }
      .footerwrap .wrap#nlsubscription a img, .footerwrap .wrap#nlsubscription button img {
        height: 35px;
        width: auto; }
        .footerwrap .wrap#nlsubscription a img.mag, .footerwrap .wrap#nlsubscription button img.mag {
          height: 31px; }
  .footerwrap .wrap#credits {
    font-size: 1em;
    line-height: 1.5em;
    color: #282828; }
    .footerwrap .wrap#credits div {
      padding-top: 20px; }
      @media only screen and (min-width: 992px) {
        .footerwrap .wrap#credits div {
          padding-top: 94px; } }
      @media only screen and (min-width: 992px) {
        .footerwrap .wrap#credits div#address {
          padding-top: 20px; } }
      .footerwrap .wrap#credits div#address img {
        width: 43px;
        height: auto; }
    .footerwrap .wrap#credits address {
      text-align: left;
      border: 0 dotted DodgerBlue;
      font-size: 1em;
      margin: 1em 0;
      padding: 0;
      /* @media only screen and (min-width: 992px) { display: inline-block; vertical-align: bottom; float: none; } */ }
      @media only screen and (min-width: 768px) {
        .footerwrap .wrap#credits address {
          font-size: 1em;
          margin: 1em 0 0 0; } }
      .footerwrap .wrap#credits address p {
        margin: 1em 0; }
        @media only screen and (min-width: 992px) {
          .footerwrap .wrap#credits address p {
            margin: 0; } }
      .footerwrap .wrap#credits address p.phone {
        letter-spacing: 0.1em; }
        .footerwrap .wrap#credits address p.phone em {
          font-size: 3.3333em;
          margin: 0;
          line-height: 1em; }
          .footerwrap .wrap#credits address p.phone em a {
            white-space: nowrap;
            margin: 0;
            border: 0;
            color: #b2b2b2; }
      .footerwrap .wrap#credits address a {
        text-transform: none;
        color: #282828;
        margin: 0 0.2em;
        letter-spacing: 0.1em;
        font-size: 0.9em;
        border-bottom: 1px solid #b2b2b2; }
        .footerwrap .wrap#credits address a:hover {
          color: #b2b2b2;
          border-bottom: 1px solid #b2b2b2; }
    .footerwrap .wrap#credits strong {
      font-family: "Avenir Next LT W01 Demi"; }
  .footerwrap.sitemap {
    max-width: 100%;
    background-color: #d8d4bc;
    margin: 0 auto; }
    .footerwrap.sitemap .sitemapwrap {
      max-width: 1400px;
      margin: 0 auto; }
      .footerwrap.sitemap .sitemapwrap nav#sitemap {
        text-align: center;
        margin: 40px 0; }
        .footerwrap.sitemap .sitemapwrap nav#sitemap ul {
          float: none;
          display: inline-block;
          text-align: left;
          width: 100%; }
          .footerwrap.sitemap .sitemapwrap nav#sitemap ul li a {
            color: #282828; }
            .footerwrap.sitemap .sitemapwrap nav#sitemap ul li a:hover {
              color: black; }
          .footerwrap.sitemap .sitemapwrap nav#sitemap ul li.nav-item--level-1 {
            float: left;
            padding: 0 1em 1em 1em;
            width: 100%; }
            @media only screen and (min-width: 768px) {
              .footerwrap.sitemap .sitemapwrap nav#sitemap ul li.nav-item--level-1 {
                width: 50%; } }
            @media only screen and (min-width: 992px) {
              .footerwrap.sitemap .sitemapwrap nav#sitemap ul li.nav-item--level-1 {
                width: 33.3333%; } }
            .footerwrap.sitemap .sitemapwrap nav#sitemap ul li.nav-item--level-1 a {
              font-family: "Avenir Next LT W01 Bold";
              margin: 0 0 0.5em 0;
              float: left;
              width: 100%; }
          .footerwrap.sitemap .sitemapwrap nav#sitemap ul li ul li {
            line-height: 1.5em;
            padding: 0; }
            .footerwrap.sitemap .sitemapwrap nav#sitemap ul li ul li.nav-item--level-2 a {
              font-family: "AvenirNextLTW01-Regular", Arial, Helvetica, sans-serif;
              margin: 0; }

/*______ Tables ______*/
main#content article table {
  width: 100% !important;
  margin: 1.5em 0 3em 0;
  font-size: 0.8em;
  line-height: 150%; }
  @media only screen and (min-width: 480px) {
    main#content article table {
      font-size: 1em; } }
  @media only screen and (min-width: 768px) {
    main#content article table {
      font-size: 0.8em; } }
  @media only screen and (min-width: 992px) {
    main#content article table {
      font-size: 1em; } }
  main#content article table th {
    font-weight: bold; }
  main#content article table td {
    padding: 0 1em 0 0; }
    @media only screen and (min-width: 480px) {
      main#content article table td {
        padding: 0 2em 0 0; } }
    main#content article table td img {
      float: left;
      width: 90px;
      height: auto;
      background: #eee; }
    main#content article table td h1 {
      font-size: 1em !important;
      margin: 0 0 5px 0; }
    main#content article table td h2 {
      font-size: 0.9em !important;
      margin: 20px 0 5px 0; }
    main#content article table td h3 {
      font-size: 0.8em !important;
      color: #777;
      margin: 20px 0 5px 0; }
    main#content article table td p {
      font-size: 0.9em;
      margin: 5px 0 0 0; }
    main#content article table td p.date {
      font-size: 0.9em;
      margin: 0 0 5px 0; }
    main#content article table td small {
      font-size: 0.85em;
      margin: 0; }
    main#content article table td ul {
      font-size: 0.9em;
      margin: 0 0 0 1em;
      padding-left: 2px;
      text-indent: -0.9em; }
    main#content article table td li {
      line-height: 130%;
      padding: 0 0 0.5em 0; }

table.tablesorter {
  float: left;
  width: 974px;
  margin: 0 0 0 0; }
  table.tablesorter th {
    border-bottom: 1px solid #ddd; }
  table.tablesorter td {
    padding: 10px 30px 10px 0;
    border-bottom: 1px solid #ddd; }
    table.tablesorter td img {
      float: left;
      width: 90px;
      height: auto;
      background: #eee; }
    table.tablesorter td h1 {
      font-size: 1em !important;
      margin: 0 0 5px 0; }
    table.tablesorter td h2 {
      font-size: 0.9em !important;
      margin: 20px 0 5px 0; }
    table.tablesorter td h3 {
      font-size: 0.8em !important;
      color: #777;
      margin: 20px 0 5px 0; }
    table.tablesorter td p {
      font-size: 1em;
      margin: 5px 0 0 0; }
    table.tablesorter td p.date {
      font-size: 0.9em;
      margin: 0 0 5px 0; }
    table.tablesorter td small {
      font-size: 0.85em;
      margin: 0; }
    table.tablesorter td ul {
      font-size: 0.9em;
      margin: 0 0 0 1em;
      padding-left: 2px;
      text-indent: -0.9em; }
    table.tablesorter td li {
      line-height: 130%;
      padding: 0 0 0.5em 0; }

/*______ Forms ______*/
div#loginpress {
  width: 300px;
  float: left; }

form#register_member_form {
  width: 300px;
  float: right;
  margin: 20px 10px 0 0; }

form {
  margin: 20px 0 20px 0; }
  form label {
    margin: 1.3em 0 0 0; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999 !important; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999 !important;
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999 !important;
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important; }

:placeholder-shown {
  /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: #999 !important; }

/* textarea { width: 300px; height: 200px; }
	input { width: 300px; height: 20px; margin: 0 0 0 0; padding: 0; color: #000 !important; text-transform: uppercase; }
	::-webkit-input-placeholder { color:#000 !important; text-transform: uppercase !important; }
	::-moz-placeholder { color:#000 !important; text-transform: uppercase !important; }
	:-ms-input-placeholder { color:#000 !important; text-transform: uppercase !important; }
	input:-moz-placeholder { color:#000 !important; text-transform: uppercase !important; }
	
	input[type=submit] { width: auto; height: auto; 
  display: inline-block;
  *display: inline;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  *margin-left: .3em;
  font-size: 13px;
  line-height: 18px;
  *line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  *background-color: #e6e6e6;
  background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  *border: 0;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  &:hover, &:active, &.active, &.disabled,&[disabled] {
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
  }
  }
  div.line { float: left; width: 100%; }
  ul { display: block; }
  ul li { list-style: none; display: inline; float: left; width: 100px;
	  label { margin: 5px 0 0 0; }
  }
  ul li:before { content: none !important; }
}
form input[type=checkbox], form input[type=radio] { width: auto; margin: 0 20px 0 0; }

dl { clear:both; }
dt { float:left; width: 104px; }
dd { float:left; width:auto; padding: 0 0 20px 0; }

fieldset { border: 0; margin: 0; padding: 0; }

form p { line-height: 1em !important; }*/
